<template>
  <v-footer dark class="py-5">

    <v-container>
      <v-row justify="center">
        <v-col cols="12" sm="10" md="6" lg="3" class="text-center text-md-start">
          <div v-lazy-container="{ selector: 'img' }" class="mb-2">
            <img data-error="@/assets/img/default.svg"
              v-lazy="$store.state.endpointURL + $store.state.website_basic_data.footer_logo" :height="75"
              alt="the concept" />
          </div>

          <div class="subtitle-2" v-if="$store.state.website_basic_data.whatsapp">
            {{ $t('whatsapp') | capitalize }} : <span class="font-weight-bold">{{
                $store.state.website_basic_data.whatsapp
            }}</span>
          </div>
          <div class="subtitle-2" v-if="$store.state.website_basic_data.email">
            {{ $t('email') | capitalize }} : <span class="font-weight-bold">{{ $store.state.website_basic_data.email
            }}</span>
          </div>
          <div class="subtitle-2">
            <span class="font-weight-bold">{{ $store.state.website_basic_data.footer_title }}</span>
          </div>
          <div class="subtitle-2" v-if="$store.state.website_basic_data.vat_registration_number">
            {{ $t('vat register number') | capitalize }} : <span class="font-weight-bold">{{
                $store.state.website_basic_data.vat_registration_number
            }}</span>
          </div>
          <v-col cols="12" class="mt-3">
            <v-row align="center" justify="center">
              <v-col cols="auto" v-if="$store.state.website_basic_data.facebook_link">
                <a :href="$store.state.website_basic_data.facebook_link" target="_blank">
                  <img src="@/assets/img/svg/ic-facebook.svg" alt="the concept">
                </a>
              </v-col>
              <v-col cols="auto" v-if="$store.state.website_basic_data.twitter_link">
                <a :href="$store.state.website_basic_data.twitter_link" target="_blank">
                  <img src="@/assets/img/svg/ic-twitter.svg" alt="the concept">
                </a>
              </v-col>
              <v-col cols="auto" v-if="$store.state.website_basic_data.instagram_link">
                <a :href="$store.state.website_basic_data.instagram_link" target="_blank">
                  <img src="@/assets/img/svg/ic-instagram.svg" alt="the concept">
                </a>
              </v-col>
              <v-col cols="auto" v-if="$store.state.website_basic_data.google_plus_link">
                <a :href="$store.state.website_basic_data.google_plus_link" target="_blank">
                  <img src="@/assets/img/svg/ic-google.svg" alt="the concept">
                </a>
              </v-col>
            </v-row>
          </v-col>
        </v-col>

        <v-col cols="6" sm="6" md="6" lg="3" class="">
          <div class="subtitle-1 font-weight-bold">
            {{ $t('links') | capitalize }}
          </div>


          <router-link :to="`/about`" class="footerLink">
            {{ $t('about us') | capitalize }}
          </router-link>

          <router-link :to="`/contact`" class="footerLink">
            {{ $t('contact us') | capitalize }}
          </router-link>

          <router-link :to="`/privacy`" class="footerLink">
            {{ $t('privacy policy') | capitalize }}
          </router-link>

          <router-link :to="`/delivery`" class="footerLink">
            {{ $t('delivery information') | capitalize }}
          </router-link>

          <router-link :to="`/return`" class="footerLink">
            {{ $t('return policy') | capitalize }}
          </router-link>

          <router-link :to="`/faq`" class="footerLink">
            {{ $t('FAQs') | capitalize }}
          </router-link>

          <router-link :to="`/gift`" class="footerLink">
            {{ $t('gift cards policy') | capitalize }}
          </router-link>

          <router-link :to="`/third-party-websites`" class="footerLink">
            {{ $t('third party websites') | capitalize }}
          </router-link>


        </v-col>
        <v-col cols="6" sm="6" md="6" lg="3" class="">
          <div class="subtitle-1 font-weight-bold">
            {{ $t('users') | capitalize }}
          </div>


          <router-link :to="`/profile`" class="footerLink">
            {{ $t('profile') | capitalize }}
          </router-link>

          <router-link :to="`/register`" class="footerLink">
            {{ $t('subscribe with us') | capitalize }}
          </router-link>

          <router-link :to="`/personal-privacy`" class="footerLink">
            {{ $t('personal privacy') | capitalize }}
          </router-link>

          <router-link :to="`/access-options`" class="footerLink">
            {{ $t('access, modification and options') | capitalize }}
          </router-link>

          <router-link :to="`/information-period`" class="footerLink">
            {{ $t('information retention period') | capitalize }}
          </router-link>

          <router-link :to="`/sensitive-information`" class="footerLink">
            {{ $t('sensitive information') | capitalize }}
          </router-link>

          <router-link :to="`/security`" class="footerLink">
            {{ $t('security') | capitalize }}
          </router-link>
        </v-col>

        <v-col cols="12" sm="10" md="6" lg="3" class="text-center text-md-start">
          <div class="subtitle-1 font-weight-bold">
            {{ $t('The Concept Application') | capitalize }}
          </div>


          <a :href="$store.state.website_basic_data.link_in_app_store" target="_blank"  class="my-3 d-block">
            <img src="@/assets/img/svg/appStore.svg" height="70" class="d-block ma-auto" alt="the concept">
          </a>

          <a :href="$store.state.website_basic_data.link_in_google_play" target="_blank"  class="my-3 d-block">
            <img src="@/assets/img/svg/googleApp.svg" height="70" class="d-block ma-auto" alt="the concept">
          </a>


        </v-col>
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12">
          <div class="text-center subtitle-2">
            {{ $t('The Concept - shaving services and grooming products') | capitalize }} 
            {{ $t('all rights reserved') | capitalize }} {{ new Date().getFullYear() }} 
             &copy; {{ $t('cloud secrets') | capitalize }}
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>


<script>

export default {
  name: "FooterSection",
  props: {},
  data: () => ({}),
  computed: {},
  watch: {},
  mounted() {
  },
  beforeDestroy: function () {
  },
  methods: {},
  components: {}
};
</script>



<style scoped lang="scss">
.v-footer {
  width: 100vw;
  position: relative;
  min-height: 150px;
  display: flex;
  align-items: center;
  background-color: #1d1f1f;

  .footerLink {
    opacity: 0.7;
    transition: 0.5s;
    color: var(--white);
    display: block;
    margin: 0.5rem 0;

    &:hover {
      opacity: 1;
    }
  }
}

.v-application--is-rtl {}
</style>
