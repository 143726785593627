import { render, staticRenderFns } from "./ProductPage.vue?vue&type=template&id=6a51e234&scoped=true&"
import script from "./ProductPage.vue?vue&type=script&lang=js&"
export * from "./ProductPage.vue?vue&type=script&lang=js&"
import style0 from "./ProductPage.vue?vue&type=style&index=0&id=6a51e234&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a51e234",
  null
  
)

export default component.exports