<template>
  <section id="about-section" fluid>
    <v-container class="px-10" fluid>
      <v-row align="center">
        <v-col cols="12" lg="9">
          <div class="text-h4 text-center text-md-start font-weight-bold position-relative">
            <v-divider class="title-divider" />
            {{ $t('privacy policy') | capitalize }}
          </div>
          <div class="my-5 text-center text-md-start" 
            v-html="$store.state.website_basic_data.website_policy || '<p>-</p>'" />
          <div class="appointment-btn mt-5" v-ripple @click="$router.push(`/booking`)">
            <div class="px-5">{{ $t('request booking') }}</div>
            <img src="@/assets/img/svg/appointmentWhite.svg" height="50" alt="">
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>


export default {
  name: "PrivacyPage",

  components: {

  },

  data: () => ({
    data: null,
    title: 'privacy policy',
    metaTitle: null,
    metaDescription: null,
    isLoading: false,
  }),
  head() {
    return {
      title: this.$i18n.t(this.title),

    }
  },
  mounted() {
    // this.getData()
  },
  watch: {
    $route() {
      // this.getData();
    },
  },
  methods: {
    // getData() {
    //   this.$store.state.splashScreen = true;
    //   this.$api.GET_METHOD(`home`).then((response) => {
    //     this.$store.state.splashScreen = false;
    //     if (response.check) {
    //       this.$store.commit("SET_DEFAULTS_DATA", response.data);
    //     }
    //   })
    // },


  },

}
</script>

<style scoped lang="scss">
#about-section {
  padding: 100px 0;
  min-height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  align-items: center;
}
</style>
