<template>
  <v-container fluid class="pa-0">
    <v-card class="pa-5 px-7 rounded-lg shadow">
      <v-row justify="end" justify-lg="space-between" align="center">
        <v-col cols="12" sm="6" lg="auto">
          <div class="font-weight-bold text-h6">{{ $t('personal profile') | capitalize }}</div>
        </v-col>
        <v-col cols="12" sm="6" lg="auto" class="d-flex justify-end">
          <v-btn class=" font-weight-bold primary--text" v-if="!editForm" @click="editForm = true" depressed>
            <v-icon left>mdi-pencil</v-icon>
            {{ $t('edit') }}
          </v-btn>
          <v-btn class="font-weight-bold mx-2 rounded-lg" width="120" large text color="error" v-if="editForm && !$route.query.edit"
            @click="editForm = false" depressed>
            {{ $t('back') }}
          </v-btn>
          <v-btn class="font-weight-bold mx-2 rounded-lg" width="120" large color="success" v-if="editForm" @click="save"
            :loading="isLoading" :disabled="!(valid)" depressed>
            {{ $t('save') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row align="center" v-if="!editForm">
        <v-col cols="12" md="12" lg="auto">
          <v-avatar color="light" size="125" class="d-block ma-auto" v-lazy-container="{ selector: 'img' }">
            <img class="pa-1" :data-src="$store.state.endpointURL + $store.state.userData.image"
              :alt="$store.state.userData.full_name" />
          </v-avatar>
        </v-col>
        <v-col cols="12" md="12" lg="auto" class="px-4 text-center text-lg-start">
          <div class="font-weight-bold text-h6">{{ $store.state.userData.full_name | capitalize }}</div>
          <div class="subtitle-1">{{ $store.state.userData.mobile | capitalize }}</div>
          <div class="subtitle-1">{{ $store.state.userData.email }}</div>
        </v-col>
      </v-row>


      <v-form ref="form" v-model="valid" class="py-5" v-if="editForm">
        <v-row>
          <v-col cols="12" md="12" lg="2">
            <v-avatar color="light" size="125" class="d-block ma-auto" v-lazy-container="{ selector: 'img' }">
              <img class="pa-1" :data-src="$store.state.endpointURL + $store.state.userData.image"
                :alt="$store.state.userData.full_name" />
            </v-avatar>
          </v-col>
          <v-col cols="12" md="12" lg="10" class="px-4">
            <v-row align="center">
              <v-col cols="12" md="12" lg="6" class="py-0">
                <label class="pa-3">{{ $t('first name') | capitalize }}</label>
                <GenericInput type="text" name="first_name" :value="form.first_name" @input="form.first_name = $event"
                  label="" :required="true" :isLoading="false" :cols="[12, 12, 12]">
                </GenericInput>
              </v-col>
              <v-col cols="12" md="12" lg="6" class="py-0">
                <label class="pa-3">{{ $t('last name') | capitalize }}</label>
                <GenericInput type="text" name="last_name" :value="form.last_name" @input="form.last_name = $event"
                  label="" :required="true" :isLoading="false" :cols="[12, 12, 12]">
                </GenericInput>
              </v-col>
              <v-col cols="12" md="12" lg="6" class="py-0">
                <label class="pa-3">{{ $t('email') | capitalize }}</label>
                <GenericInput type="email" :disabled="($store.state.userData.email)" name="email" :value="form.email"
                  @input="form.email = $event" label="" :required="true" :isLoading="false" :cols="[12, 12, 12]">
                </GenericInput>
              </v-col>
              <v-col cols="12" md="12" lg="6" class="py-0">
                <label class="pa-3">{{ $t('mobile') | capitalize }}</label>
                <GenericInput type="mobile" :disabled="($store.state.userData.mobile)" name="mobile" :value="form.mobile"
                  @input="form.mobile = $event" label="" :required="true" :isLoading="false" :cols="[12, 12, 12]">
                </GenericInput>
              </v-col>
              <v-col cols="12" md="12" lg="12">
                <GenericInput type="dropzone" name="image" :value="form.image" @input="form.image = $event"
                  label="add your profile image" :required="true" :isLoading="false" :cols="[12, 12, 12]">
                </GenericInput>
              </v-col>

            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-container>
</template>
 
<script>
import GenericInput from "@/components/ui/GenericInput.vue"
export default {
  name: "ProfileDataPage",

  data: () => ({
    editForm: false,
    isLoading: false,
    valid: false,
    title: 'my profile',
    form: {
      full_name: null,
      first_name: null,
      last_name: null,
      mobile: null,
      email: null,
      password: null,
      confirm_password: null,
      image: null,
    }
  }),
  head() {
    return {
      title: this.$i18n.t(this.title),

    }
  },

  mounted() {
    if (this.$route.query.edit) {
      this.editForm = true;
    }
  },
  components: {
    GenericInput
  },
  watch: {
    '$store.state.userData'() {
      this.form = {
        first_name: this.$store.state.userData.first_name,
        last_name: this.$store.state.userData.last_name,
        mobile: this.$store.state.userData.mobile,
        email: this.$store.state.userData.email,
        image: this.$store.state.userData.image,
        password: null,
        confirm_password: null,
      };
    },
    editForm() {
      this.form = {
        first_name: this.$store.state.userData.first_name,
        last_name: this.$store.state.userData.last_name,
        mobile: this.$store.state.userData.mobile,
        email: this.$store.state.userData.email,
        image: this.$store.state.userData.image,
        password: null,
        confirm_password: null,
      };
    }

  },
  methods: {
    save() {
      this.$refs.form.validate()
      if (this.valid) {
        this.isLoading = true;
        this.form.full_name = this.form.first_name + ' ' + this.form.last_name;
        this.form.image = String(this.form.image).substring(0, 10) == 'data:image' ? this.form.image : null
        this.$api.POST_METHOD(`update_profile`, this.form).then((response) => {
          this.isLoading = false;
          if (response.check) {
            this.editForm = false
            this.$api.SET_AUTH(response.data);
          } else {
            // this.$api.ShowModal('error', response.msg);
          }
        })
      } else {
        this.$api.ShowModal('warning', this.$i18n.t('all field is required'));
      }

    }
  },

}
</script>
 