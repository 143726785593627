<template>
  <v-container fluid class="pa-0">
    <LandingSection v-if="$store.state.sections.landing_section" />
    <AboutSection v-if="$store.state.sections.about_us" />
    <ApplicationSection v-if="$store.state.sections.about_us" />
    <RecentlyArrivedSection v-if="$store.state.new_arrivals.length > 0" />
  </v-container>
</template>


<script>
import AboutSection from '@/components/ui/AboutSection.vue';
import ApplicationSection from '@/components/ui/ApplicationSection.vue';
import LandingSection from '@/components/ui/LandingSection.vue';
import RecentlyArrivedSection from '@/components/ui/RecentlyArrivedSection.vue';


export default {
  name: "HomeModule",
  components: {
    LandingSection,
    AboutSection,
    ApplicationSection,
    RecentlyArrivedSection
},
  data: () => ({

  }),
  watch: {

  },
  created() {

  },
  destroyed() {

  },
  computed: {},
  mounted() {
  },
  methods: {

  }

};
</script>


<style lang="scss" scoped>

</style>