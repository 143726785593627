<template>
  <v-textarea v-model="input" :solo="solo" outlined :reverse="reverse" :dense="dense"
    :name="name" @keydown.enter="keydownEnter()" :class="classes" :disabled="disabled" :rules="
    required == true ? $store.state.Settings.requiredRule : $store.state.Settings.notRequiredRule" :loading="isLoading"
    rounded-md :no-resize="true" :color="color" :append-icon="icon" :hide-details="hideDetails == true ? 'auto' : false"
    :rows="rowsNumber" :type="type">
    <template v-slot:label>
      <label v-if="labelInline == false">
        {{ $t(label) }}
        <span class="error--text" v-if="required == true">*</span>
      </label>
    </template>
    <template v-slot:append-outer>
      <slot name="append-outer" />
    </template>
    <template v-slot:append>
      <slot name="append" />
    </template>
    <template v-slot:prepend-inner>
      <slot name="prepend-inner" />
    </template>
    <template v-slot:prepend>
      <slot name="prepend" />
    </template>
  </v-textarea>
</template>


<script>
export default {
  name: "TextAreaInput",
  props: {
    name: { default: null },
    label: { default: null },
    type: { default: null },
    value: { default: null },
    required: { default: false },
    dense: { default: true },
    onChange: { type: Function },
    isLoading: { default: null },
    disabled: { default: null },
    color: { default: "secondary" },
    icon: { default: "" },
    hideDetails: { default: false},
    labelInline: { default: false },
    clearable: { default: false },
    hideImage: { default: false },
    onClickOuter: { type: Function },
    keydownEnter: { type: Function },
    placeholder: { default: null },
    solo: { default: true},
    classes: { default: null },
    dir: { default: null },
    reverse: { default: false },
    rowsNumber: { default: 3 },
  },
  data: () => ({

  }),
  computed: {
    input: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {

  },
  mounted() {

  },
  beforeDestroy: function () {

  },
  methods: {

  },
};
</script>



<style scoped lang="scss">

</style>
