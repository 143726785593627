import { render, staticRenderFns } from "./WishlistPage.vue?vue&type=template&id=35a9f135&scoped=true&"
import script from "./WishlistPage.vue?vue&type=script&lang=js&"
export * from "./WishlistPage.vue?vue&type=script&lang=js&"
import style0 from "./WishlistPage.vue?vue&type=style&index=0&id=35a9f135&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35a9f135",
  null
  
)

export default component.exports