<template>
  <section id="recently-arrived-section" v-if="$store.state.new_arrivals.length > 0">
    <v-container fluid class="px-0">
      <v-col cols="12">
        <div class="text-h4 text-center font-weight-bold">{{ $t('recently arrived') | capitalize }}</div>
      </v-col>

      <v-card id="recently-arrived-products" class="shadow-none py-2 my-5">

        <ProductCard  v-for="(product) in $store.state.new_arrivals" :product="product" :key="product.id" />

      </v-card>
    </v-container>
  </section>
</template>


<script>
import ProductCard from '@/components/ui/ProductCard';
export default {
  name: "RecentlyArrivedSection",
  props: {
  },
  data: () => ({
    items: []
  }),
  computed: {

  },
  watch: {},
  components: {
    ProductCard
  },
  mounted() {
    this.dragTasks();
  },
  methods: {
    dragTasks() {
      const slider = document.getElementById("recently-arrived-products");
      let mouseDown = false;
      let startX, scrollLeft, startY, scrollTop;
      let startDragging = function (e) {
        mouseDown = true;
        startX = e.pageX - slider.offsetLeft;
        startY = e.pageY - slider.offsetTop;
        scrollLeft = slider.scrollLeft;
        scrollTop = slider.scrollTop;
      };
      let stopDragging = function () {
        mouseDown = false;
      };
      slider.addEventListener('mousemove', (e) => {
        e.preventDefault();
        if (!mouseDown) { return; }
        const x = e.pageX - slider.offsetLeft;
        const y = e.pageY - slider.offsetTop;
        const scroll = x - startX;
        const scrollY = y - startY;
        slider.scrollLeft = scrollLeft - scroll;
        slider.scrollTop = scrollTop - scrollY;
      });
      slider.addEventListener('mousedown', startDragging, false);
      slider.addEventListener('mouseup', stopDragging, false);
      slider.addEventListener('mouseleave', stopDragging, false);
    },

  },
};
</script>



<style scoped lang="scss">
#recently-arrived-section {
  margin: 3rem 0;
}

#recently-arrived-products {
  display: flex;
  overflow-x: auto;
  width: 100%;
  cursor: grab;

  &::-webkit-scrollbar {
    height: 0px;
    opacity: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--blue9) !important;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--accent) !important;
  }
}
</style>
