<template>
    <v-list  class="py-10">
      <v-list-item>
        <v-btn text  :min-width="120" plain exact active-class="border-bottom-primary" class="rounded-pill" :to="`/`">
          {{ $t('home') }}
        </v-btn>
      </v-list-item>
      <v-list-item>
        <v-btn text  :min-width="120" plain exact active-class="border-bottom-primary" class="rounded-pill" :to="`/products`">
          {{ $t('products') }}
        </v-btn>
      </v-list-item>
      <v-list-item>
        <v-btn text  :min-width="120" plain exact active-class="border-bottom-primary" class="rounded-pill" :to="`/blogs`">
          {{ $t('blogs') }}
        </v-btn>
      </v-list-item>
      <v-list-item>
        <v-btn color="primary" :min-width="120" depressed class="rounded-pill" :to="`/booking`">
          {{ $t('request booking') }}
        </v-btn>
      </v-list-item>
      <v-list-item>
        <v-btn text  :min-width="120" class="rounded-pill" @click="changeLanguage">
          {{ $vuetify.rtl ? 'English' : 'العربي' }}
        </v-btn>
      </v-list-item>
      <v-list-item>
        <v-divider class="py-0 mx-5"></v-divider>
      </v-list-item>
      <v-list-item v-if="$store.state.isAuth">
        <v-btn  :min-width="120" depressed plain exact text :to="`/profile`" class="rounded-pill">
          {{ $t('my profile') }}
        </v-btn>
      </v-list-item>

      <v-list-item v-if="!$store.state.isAuth">
        <v-btn  text :to="`/login`" plain exact class="rounded-pill">
          {{ $t('login') }}
        </v-btn>
      </v-list-item>
      <v-list-item v-if="$store.state.isAuth">
        <v-btn  :min-width="120" depressed plain exact text :to="`/wishlist`" class="rounded-pill">
          {{ $t('favorites') }}
        </v-btn>
      </v-list-item>
      <v-list-item>
        <v-btn  text  class="rounded-pill" :to="`/cart`">
          <div>{{$t('shopping cart')}}</div>
          <img src="@/assets/img/svg/darkCart.svg" class="mx-2" height="20" />
          <v-avatar size="20" color="primary">
           <small class="white--text">0</small>
          </v-avatar>
        </v-btn>

      </v-list-item>
    </v-list>
</template>
<style scoped lang="scss" >
.border-bottom-primary{
  border-bottom: 1px solid var(--primary);
  border-radius: 0 !important;
}
</style>
<script>

export default {
  name: "SideBar",
  computed: {

  },
  data: () => ({

  }),
  mounted() {

  },

  methods: {
    logout() {
      this.$api.LOGOUT();
    },
    changeLanguage() {
      this.$store.dispatch("Settings/changeLanguage");
    },
  },
};
</script>
