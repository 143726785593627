<template>
   <section id="auth-page">


      <v-container fluid class="mt-16 mt-md-5">
         <v-row justify="center">
            <v-col cols="12" md="6" sm="5" order="1" order-sm="2" class="pa-0 d-none d-md-block col-bg"
               :style="{ 'min-height': $store.state.windowSize.y + 'px' }" v-lazy-container="{ selector: 'img' }">
               <img :data-src="$store.state.endpointURL + $store.state.website_basic_data.main_logo"
                  class="absolute-center" :height="133.52" />
            </v-col>
            <v-col cols="12" md="6" sm="12" order="2" order-sm="1"
               class="white w-100 pa-7 p-md-3 d-flex align-center form-bg">
               <v-card elevation="0" class="pa-5 mt-2 form-card">

                  <v-card-title class="d-flex align-center" v-if="!user_id">
                     <div class="text-h5 font-weight-bold">{{ $t('login') | capitalize }}</div>
                  </v-card-title>




                  <v-row justify="center" class="mt-5">
                     <v-col cols="12" sm="12" class="py-0">

                        <v-col cols="12" sm="12" class="py-2" v-if="!user_id">
                           <label class="px-3">
                              <div class="subtitle-1 gray7--text px-3" v-if="!user_id">
                                 {{ $t('enter your mobile to complete the login process') | capitalize }}
                              </div>
                           </label>
                           <GenericInput type="mobile" name="mobile" :value="form.mobile" :keydownEnter="() => login"
                              @input="form.mobile = $event" :required="true" :isLoading="isLoading" :cols="[12, 12, 12]">
                           </GenericInput>
                        </v-col>
                        <v-col cols="12" sm="12" v-if="user_id">
                           <v-alert outlined type="success"> {{ $t('code is sent on') + ' ' + form.mobile }}</v-alert>
                        </v-col>
                        <v-col cols="12" sm="12" class="py-2 px-5" v-if="user_id">
                           <v-otp-input v-model="form.otp" :length="4" type="number" :disabled="isLoading"
                              @finish="loginOTP" />
                        </v-col>

                     </v-col>

                     <v-col cols="12" class="px-9"  v-if="!user_id">
                        <v-btn color="primary" block :width="180" :height="50" @click="login"
                           class="shadow subtitle-1 font-weight-bold rounded-lg mx-auto d-block"
                           :loading="isLoading == true">
                           {{ $t('login') }}</v-btn>
                     </v-col>
                     <v-col cols="12" v-if="!user_id">
                        <v-row class="align-center justify-center">
                           <v-col cols="auto">
                              <v-btn outlined color="#D0D5DD" @click="loginWithGoogle" class="rounded-lg" depressed
                                 width="48" height="48">
                                 <img src="@/assets/img/svg/google.svg" height="35" alt="the concept">
                              </v-btn>
                           </v-col>
                           <v-col cols="auto">
                              <v-btn color="#0c88ef" @click.prevent="loginWithFacebook" class="rounded-lg" depressed
                                 width="48" height="48">
                                 <v-icon size="35" color="white">mdi-facebook</v-icon>
                              </v-btn>
                           </v-col>
                        </v-row>
                     </v-col>
                  </v-row>
               </v-card>
            </v-col>
         </v-row>
      </v-container>
   </section>
</template>
 
<script>
import GenericInput from '@/components/ui/GenericInput.vue'
import { initFbsdk } from '@/config/facebook.js';
export default {
   name: "LoginPage",
   components: {
      GenericInput
   },

   data: () => ({
      data: null,
      title: 'login',
      isLoading: false,
      showPass: false,
      valid: false,
      user_id: null,

      form: {
         mobile: null,
         otp: null,
      }
   }),
   head() {
      return {
         title: this.$i18n.t(this.title),
      }
   },
   mounted() {
      initFbsdk()
   },
   methods: {
      login() {
         if (this.form.mobile) {
            this.isLoading = true;
            this.$api.POST_METHOD(`login_mobile`, this.form).then((response) => {
               this.isLoading = false;
               if (response.check) {
                  this.user_id = response.data.user_id
               }
            })
         } else {
            this.$store.state.snackbarTitle = this.$i18n.t('Please enter mobile number');
            this.$store.state.snackbarType = 'error';
            this.$store.state.showSnackbar = true;
         }


      },
      loginOTP() {
         if (this.form.mobile && this.form.otp) {
            this.isLoading = true;
            this.$api.POST_METHOD(`login_otp`, this.form).then((response) => {
               this.isLoading = false;
               if (response.check) {
                  this.$api.SET_AUTH(response.data);
                  if (!response.data.first_name || !response.data.last_name || !response.data.email) {
                     this.$router.push(`/profile?edit=true`)
                  } else {
                     this.$router.push(`/`)
                  }
               }
            })
         } else {
            this.$store.state.snackbarTitle = this.$i18n.t('please write code');
            this.$store.state.snackbarType = 'warning';
            this.$store.state.showSnackbar = true;
         }


      },
      loginWithGoogle() {
         this.$gAuth
            .signIn()
            .then(GoogleUser => {
               this.register({
                  user_id: GoogleUser.getId(),
                  name: GoogleUser.getBasicProfile().getName(),
                  email: GoogleUser.getBasicProfile().getEmail(),
                  image: GoogleUser.getBasicProfile().getImageUrl(),
                  driver: 'g',
               })
            })
            .catch(error => {
               console.log('error', error)
            })
      },
      loginWithFacebook() {
         window.FB.login(response => {
            if (response && response.authResponse) {
               console.log('response', response)
               window.FB.api(`/${response.authResponse.userID}`, userResponse => {
                  if (userResponse) {
                     this.register({
                        user_id: userResponse.id,
                        name: userResponse.name,
                        email: null,
                        image: null,
                        driver: 'f',
                     })
                  }
               }, this.params);
               // next
            }
         }, this.params)
      },
      register(user) {
         console.log('user', user);
         this.isLoading = true;
         this.$api.POST_METHOD(`login_social`, user).then((response) => {
            this.isLoading = false;
            if (response.check) {
               this.$api.SET_AUTH(response.data);
               this.$router.push(`/`)
            }
         })
      }
   },

}
</script>
 

<style lang="scss" scoped>
.col-bg {
   background-image: url('../../../assets/img/png/auth_bg.png');
   // background-repeat: no-repeat;
   background-attachment: fixed;
   position: relative;
   overflow: hidden;
   // background-position: center;
}

.form-bg {
   @media screen and (max-width: 960px) {
      background-image: url('../../../assets/img/png/auth_bg.png');
      background-attachment: fixed;
      position: relative;
      background-repeat: initial;
      background-position: center;
   }

}

.form-card {
   background: rgba(255, 255, 255, 0.8);
   backdrop-filter: blur(12.5px);
}

.or {
   position: absolute;
   left: 50%;
   right: 50%;
   top: 1.2rem;
   width: 40px;
   text-align: center;
   font-weight: bold;
}

.google {
   border: 1px solid #d2d1d4;
   text-transform: initial;

   img {
      height: 30px;
   }
}
</style>