<template>
  <div class="text-center" id="appBarMenu">
    <v-menu :close-on-content-click="false" :nudge-width="200" offset-y bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="white" icon fab x-small v-bind="attrs" v-on="on">
          <v-icon>mdi-menu-down</v-icon>
        </v-btn>
      </template>

      <v-card class="shadow">
        <v-list color="transparent" class="d-lg-none d-block">
          <v-list-item class="d-lg-none d-flex justify-center align-center">
            <v-btn :min-width="120" text :to="`/${$i18n.locale}/login`">
              {{ $t('login') }}
            </v-btn>
            <v-divider class="py-4 mx-2" vertical height="60"></v-divider>
            <v-btn :min-width="120" text :to="`/${$i18n.locale}/register`">
              {{ $t('signup') }}
            </v-btn>
          </v-list-item>
        </v-list>
        <v-list color="transparent" class="d-lg-none d-flex">
          <v-divider class="py-0 mx-5"></v-divider>
        </v-list>

        <v-list color="transparent" class="d-md-none d-block">
          <v-list-item class="d-md-none d-flex justify-center align-center">
            <v-btn color="primary" :min-width="120" text class="rounded-pill" :to="`/${$i18n.locale}/booking`">
              {{ $t('request booking') }}
            </v-btn>
          </v-list-item>
        </v-list>
        <v-list color="transparent" class="d-md-none d-flex">
          <v-divider class="py-0 mx-5"></v-divider>
        </v-list>

        <v-list color="transparent">
          <v-list-item class="d-flex justify-center align-center">
            <v-btn color="primary" :min-width="120" text>
              <img src="@/assets/img/svg/gift.svg"  class="mx-1" height="20" />
              {{ $t('gift card') }}
            </v-btn>
          </v-list-item>
        </v-list>
        <v-list color="transparent">
          <v-divider class="py-0 mx-5"></v-divider>
        </v-list>
        <v-list color="transparent">
          <v-list-item class="d-flex justify-center align-center">
            <v-btn text class="text-decoration-underline" :min-width="120" @click="changeLanguage">
              {{ $vuetify.rtl ? 'English' : 'العربي' }}
            </v-btn>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>
<style scoped lang="scss" >

</style>
<script>

export default {
  name: "AppBarMenu",
  computed: {

  },
  data: () => ({

  }),
  mounted() {

  },

  methods: {
    logout() {
      this.$api.LOGOUT();
    },
    changeLanguage() {
      const nextLanguage = this.$i18n.locale === "en" ? "ar" : "en";
      this.$global.SET_COOKIE('language', nextLanguage, 360, `${nextLanguage}`).then((status) => {
        if (status) {
          this.$i18n.setLocale(nextLanguage);
          this.$vuetify.rtl = nextLanguage === "ar";
        }
      })
      this.$global.SET_COOKIE('i18n_redirected', nextLanguage, 360, `${nextLanguage}`).then(() => {

      })
    },
  },
};
</script>
