<template>
  <div id="appBar" style="position: fixed; top : 0; z-index: 55;width:100vw" class="secondary">
    <v-container class="d-block ma-auto px-7" fluid>
      <v-row justify="space-between" align="center">
        <v-col cols="3" md="auto" class="d-md-none d-block">
          <v-btn color="white" @click="toggleSidebar" icon>
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="3" md="auto" class="py-2 d-flex align-center justify-center">
          <!-- <router-link :to="`/${$i18n.locale}`" v-lazy-container="{ selector: 'img' }">
            <img :data-src="$store.state.endpointURL + $store.state.website_basic_data.main_logo" class="logo"
              height="48" />
          </router-link> -->

          <router-link to="/">
            <v-img width="70" :lazy-src="$store.state.endpointURL + $store.state.website_basic_data.main_logo"
               :alt="'the concept'"
              :src="$store.state.endpointURL + $store.state.website_basic_data.main_logo">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-skeleton-loader class="mx-auto" max-width="320" type="image"></v-skeleton-loader>
                </v-row>
              </template>
            </v-img>
          </router-link>


        </v-col>
        <v-col cols="3" md="auto" class="py-4 d-md-block d-none">
          <v-btn text color="white" :min-width="120" exact plain active-class="border-bottom-white"
            :to="`/${$i18n.locale}`">
            {{ $t('home') }}
          </v-btn>
        </v-col>
        <v-col cols="3" md="auto" class="d-md-block d-none">
          <v-btn text color="white" :min-width="120" exact plain active-class="border-bottom-white"
            :to="`/products`">
            {{ $t('products') }}
          </v-btn>
        </v-col>
        <v-col cols="3" md="auto" class="d-md-block d-none">
          <v-btn text color="white" :min-width="120" exact plain active-class="border-bottom-white"
            :to="`/blogs`">
            {{ $t('blogs') }}
          </v-btn>
        </v-col>
        <v-col cols="3" md="auto" class="d-md-block d-none">
          <v-btn color="primary" depressed class="rounded-pill" @click="booking">
            {{ $t('request booking') }}
          </v-btn>
        </v-col>
        <v-col cols="auto" md="auto" sm="auto" class=" showInXL">
          <v-btn text color="white" class="border-bottom-white" @click="changeLanguage">
            {{ $vuetify.rtl ? 'English' : 'العربي' }}
          </v-btn>
        </v-col>
        <v-col cols="3" md="auto" class="showInXL">
          <v-btn color="primary" :min-width="120" text>
            <img src="@/assets/img/svg/gift.svg" class="mx-1" height="20" />
            {{ $t('gift card') }}
          </v-btn>
        </v-col>
        <v-col cols="3" md="auto" class="d-lg-flex d-none align-center " v-if="!$store.state.isAuth">
          <v-btn color="white" :min-width="120" text :to="`/login`">
            {{ $t('login') }}
          </v-btn>
          <!-- <v-divider class="py-4" vertical height="60"></v-divider>
          <v-btn color="white" :min-width="120" text :to="`/register`">
            {{ $t('signup') }}
          </v-btn> -->
        </v-col>
        <v-col cols="3" md="auto" class="d-lg-flex d-none align-center ">

        </v-col>
        <v-col cols="3" md="auto" class="d-md-flex d-none cursor_pointer align-center">
          <v-badge color="primary" content="0" overlap v-if="$store.state.isAuth">
            <v-btn color="white" fab icon small :to="`/wishlist`">
              <v-icon>mdi-heart-outline</v-icon>
            </v-btn>
          </v-badge>



          <v-badge color="primary" content="0" overlap>
            <v-btn color="white" fab icon small to="/cart">
              <img src="@/assets/img/svg/cart.svg" class="mx-2" height="20" />
            </v-btn>
          </v-badge>
          <v-btn color="white" fab icon small :to="`/profile`" v-if="$store.state.isAuth">
            <v-icon>mdi-account-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="3" md="auto" id="dropDown">
          <AppBarMenu />
        </v-col>

      </v-row>


    </v-container>
  </div>
</template>
<script>
// import AnimatedLogo from './AnimatedLogo.vue';
import AppBarMenu from './AppBarMenu.vue';

export default {
  name: "AppBar",
  computed: {

  },
  data: () => ({

  }),

  components: {
    // AnimatedLogo,
    AppBarMenu
  },
  mounted() {

  },
  methods: {
    darkTheme() {
      this.$store.dispatch("Settings/darkTheme");
    },
    toggleSidebar() {
      this.$store.state.sidebarIsOpen = !this.$store.state.sidebarIsOpen;
    },
    changeLanguage() {
      this.$store.dispatch("Settings/changeLanguage");
    },
    booking(){
      if(this.$store.state.isAuth){
        this.$router.push('/booking')
      }else{
        this.$router.push('/login')
      }
    }

  },
};
</script>

<style lang="scss" scoped>
#appBar {
  max-height: 69px;
  overflow: hidden;

  .showInXL {
    display: block;

    @media screen and (max-width: 1300px) {
      display: none;
    }
  }

  .border-bottom-white {
    border-bottom: 1px solid var(--white);
    border-radius: 0 !important;
  }

  #dropDown {
    display: none;

    @media screen and (max-width: 1300px) {
      display: block;
    }

    @media screen and (max-width: 959.5px) {
      display: none;
    }
  }

  .v-divider {
    border-color: var(--primary);
    height: 25px;
  }
}

.active_section {
  position: relative;
  color: var(--primary) !important;

  &::before {
    content: " ";
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 7.5px;
    background-color: var(--primary);
    position: absolute !important;
    bottom: 11px !important;
    top: 78%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1 !important;
  }
}

.logo {
  max-height: 85px !important;
}
</style>