<template>
  <v-container fluid class="pa-0">
    <v-card class="pa-5 px-7 rounded-lg shadow">
      <v-col cols="12">
        <div class="text-h6 font-weight-bold">{{ $t('my previous requests') | capitalize }}</div>
        <div class="subtitle-2 font-weight-bold gray8--text">{{ $t('follow up on your previous requests') | capitalize
        }}</div>
      </v-col>
      <v-row align="center" v-for="(request, index) in 5" :key="index">
        <v-col cols="6" md="auto" lg="3">
          <v-timeline dense>
            <v-timeline-item color="primary" small>
              <v-row class="pt-1">
                <v-col cols="12">
                  <strong>{{ $t('requested') }}</strong>
                  <div class="caption gray8--text">
                    {{ 'الأربعاء: 24 يناير 2022 ' }}
                  </div>
                  <div class="caption gra8-text">
                    {{ '05:58 مساءٍ' }}
                  </div>
                </v-col>

              </v-row>
            </v-timeline-item>
            <v-timeline-item dark color="secondary" small>
              <v-row class="pt-1">
                <v-col cols="12">
                  <strong>{{ $t('delivered') }}</strong>
                  <div class="caption gray8--text">
                    {{ 'خميس: 25 يناير 2022 ' }}
                  </div>
                  <div class="caption gra8-text">
                    {{ '05:58 مساءٍ' }}
                  </div>
                </v-col>

              </v-row>
            </v-timeline-item>

          </v-timeline>
        </v-col>
        <v-col cols="auto" class="d-none d-md-block">
          <v-divider style="height : 200px" vertical></v-divider>
        </v-col>
        <v-col cols="6" md="auto" lg="4">
          <strong> {{ 5748963125 }} :ID </strong>
          <div class="subtitle-1 gray8--text mb-2">
            <span class="gray1--text">{{ $t('quantity') | capitalize }} :</span> {{ 5 }} 
            {{ $t('products') | capitalize}}
          </div>
          <div class="subtitle-1 gray8--text mb-2">
            <span class="gray1--text">{{ $t('delivery') | capitalize }} :</span>
             {{ $t('free') | capitalize }}
          </div>
          <div class="subtitle-1 gray8--text mb-2">
            <span class="gray1--text">{{ $t('payment method') | capitalize }} :</span> 
            {{ $t('cash') | capitalize }}
          </div>
          <div class="subtitle-1 gray8--text mb-2">
            <span class="gray1--text">{{ $t('total paid') | capitalize }} :</span> {{ 1000 | float }}
            {{ $t('ryal') | capitalize }}
          </div>
        </v-col>
        <v-col cols="12" lg="4" >
          <v-row justify="center">
            <v-col cols="auto" lg="12">
              <v-btn depressed class="d-block mi-start-auto" :min-width="150" color="primary" @click="$router.push(`/cart`)">
                {{$t('re-order')}}
              </v-btn>
            </v-col>
            <v-col cols="auto" lg="12">
              <v-btn depressed class="d-block mi-start-auto" :min-width="150" color="secondary" @click="$router.push(`/contact`)">
                {{$t('complaint')}}
              </v-btn>
            </v-col>
            <v-col cols="auto" :min-width="150" lg="12">
              <v-btn depressed class="d-block mi-start-auto" text color="secondary" @click="$router.push(`/products`)">
                {{$t('view products')}}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>



    </v-card>


  </v-container>
</template>
 
<script>
// import AnimatedLoading from '@/components/ui/AnimatedLoading.vue';
export default {
  name: "RequestsPage",

  components: {
    // AnimatedLoading
  },

  data: () => ({
    title: 'my requests',
    clients: [],
    isLoading: false,
    renewDialog: false,
    selectedCompany: null,
    newCompanyDialog: false,
  }),
  head() {
    return {
      title: this.$i18n.t(this.title),

    }
  },
  mounted() {
    // this.getData();

  },
  watch: {
    //  $route() {;
    //    this.getData();
    //  },
  },
  methods: {
    getData() {
      this.isLoading = true;
      this.$api.GET_METHOD(`get_my_clients`).then((response) => {
        this.isLoading = false;
        if (response.check) {
          response.data.clients.forEach(client => {
            client.remaining_days = this.$global.SubtractDates(client.end_date, this.$global.GetCurrentDate())
          });
          this.clients = response.data.clients || []
        } else {
          this.$api.ShowModal('error', response.msg);
        }
      })
    },
    openERP(client) {
      window.open(`https://qwaem.cs-testing.com/login?clientID=${client}`, '_blank')
    },
  },

}
</script>
 

<style lang="scss" >
.companyCard {
  height: 160px;
  overflow: hidden !important;
  padding: 0 !important;

  @media screen and (max-width: 1263px) {
    height: auto;
    overflow: visible;
    //     padding: 3rem !important;
  }

  .companyCardControl {
    height: 160px;

    @media screen and (max-width: 1263px) {
      height: auto;
    }
  }

  .companyCardSheet {
    height: 180px;
    padding: 2rem 1.5rem !important;

    @media screen and (max-width: 1263px) {
      padding: 3rem 4.5rem !important;
    }

    @media screen and (max-width: 580px) {
      padding: 2rem 2.5rem !important;
    }

  }
}
</style>