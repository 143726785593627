<template>
  <v-container fluid class="pa-0">
    <div class="product-banner">
      <v-row align="center" justify="center">
        <v-col class="text-center mt-15" cols="12">
          <div class="text-h3 font-weight-bold ">
            {{ $t('our products') | capitalize }}
          </div>
          <div class="subtitle-1 font-weight-bold">
            {{
                $t('browse our best carefully selected products to become distinguished in front of all.') | capitalize
            }}
          </div>
        </v-col>
      </v-row>
    </div>

    <v-container class="mt-5" fluid>
      <v-row justify="center">
        <v-col class="d-none d-md-block" cols="11" md="5" lg="3">
          <FilterCard />
        </v-col>
        <v-col cols="11" md="7" lg="9">
          <v-row align="center" justify="center">
            <v-col cols="12">
              <v-row align="center" justify="space-around" justify-md="space-between">
                <v-col cols="auto" class="d-md-none">
                  <v-btn color="gray1" text @click="filterDialog = !filterDialog">
                    <v-icon left>mdi-filter</v-icon>
                    {{ $t("filter") }}
                  </v-btn>
                </v-col>

                <v-col cols="auto" class="d-flex">
                  <v-col cols="auto" class="d-none d-md-block">
                    {{ $t("filter by") | capitalize }} :
                  </v-col>
                  <GenericInput type="select" placeholder="view" :lookups="view" selected_label="name"
                    selected_prop="id" name="view" :value="view_id" @input="view_id = $event" :required="false"
                    :multi="false" :cols="[12, 12, 12]">
                  </GenericInput>
                </v-col>

              </v-row>
            </v-col>

            <v-col cols="11" sm="6" md="6" lg="4" xl="auto" v-for="(product) in products" :key="product.id">
              <ProductCard width="auto" classes="overflow-hidden d-block ma-auto" :product="product" />
            </v-col>
            <v-col cols="12" class="pa-0" v-intersect="onIntersect" v-if="no_more == false">
              <v-row>
                <v-col cols="11" sm="6" md="4" lg="4" xl="3" class="my-5" v-for="(product, index) in 3" :key="index">
                  <v-sheet class="pa-3">
                    <v-skeleton-loader class="mx-auto" max-width="300" type="card"></v-skeleton-loader>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="pa-0 my-8" v-if="no_more">
              <v-row justify="center">
                <v-col cols="11" class="text-center text-h6  gray7--text">
                  <img src="@/assets/img/svg/no_data.svg" class="d-block ma-auto" :height="100">
                </v-col>
                <v-col cols="11" class="text-center text-h6  gray7--text">
                  {{ $t('no more products') | capitalize }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="filterDialog" overlay-opacity="0.75" fullscreen hide-overlay
      transition="dialog-bottom-transition">
      <v-card relative class="pa-16">
        <div class="pa-5 ">

          <FilterCard />
          <v-row justify="center" class="white shadow mt-2"
            style="position: sticky !important; bottom : 0; z-index: 555;">
            <v-col cols="auto" class="my-2">
              <v-btn color="primary" :width="120" @click="filterDialog = false" class="rounded-pill" depressed>
                {{ $t('filter') }}
              </v-btn>
            </v-col>
            <v-col cols="auto" class="my-2">
              <v-btn color="error" :width="120" text @click="filterDialog = false" class="rounded-pill" depressed>
                {{ $t('close') }}
              </v-btn>
            </v-col>
          </v-row>
        </div>

      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>

import ProductCard from '@/components/ui/ProductCard';
import FilterCard from '@/components/ui/FilterCard';
import GenericInput from '@/components/ui/GenericInput.vue';
export default {
  name: "ProductsPage",

  components: {
    ProductCard,
    FilterCard,
    GenericInput
  },

  data: () => ({
    data: null,
    filterDialog: false,
    no_more: false,
    title: 'all products',
    metaTitle: null,
    metaDescription: null,
    isLoading: false,
    products: [],
    view: [],
    view_id: 1,
    page: 1,
    rows: 10,
    word: '',
    department: [],
  }),
  head() {
    return {
      title: this.$i18n.t(this.title),

    }
  },
  mounted() {
    this.view = [
      {
        id: 1, name: this.$i18n.t('highest price to lowest'),
      },
      {
        id: 2, name: this.$i18n.t('lowest price to highest'),
      },
      {
        id: 3, name: this.$i18n.t('latest Products'),
      },
      {
        id: 4, name: this.$i18n.t('oldest products'),
      },
    ]

  },
  watch: {
  },
  methods: {
    onIntersect() {
      this.isLoading = true;
      this.$api.GET_METHOD(`product?page=${this.page}&rows=${this.rows}&word=${this.word || ''}&word=${this.department || ''}`).then((response) => {
        this.isLoading = false;
        if (response.check) {
          if (response.data.items.data.length > 0) {
            this.products.push(...response.data.items.data);
            this.page = this.page + 1
          } else {
            this.no_more = true
          }
        }
      })
    },
  },

}
</script>


<style lang="scss" scoped>
.product-banner {
  height: 250px;
  position: relative;
  overflow-x: hidden;
  background-image: url('../../../assets/img/svg/landing-section.svg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  color: var(--white);

  display: flex;
  align-items: center;
}
</style>