<template>
   <v-container fluid class="d-flex justify-center align-center backgroundW"
      style="height: 100vh;position: fixed; top: 0;left:0; width:100vw ; z-index: 1000;">

      <div>
            <img src="@/assets/logo.svg" alt="" height="150">
         <v-progress-circular :size="50" :width="6" style="margin: auto; " class="mt-5 d-block" color="primary"
            indeterminate></v-progress-circular>
      </div>

   </v-container>
</template>
<style  scoped>
</style>


<script>


export default {
   name: "SplashLoader",
   computed: {

   },
   components: {},
   data: () => ({

   }),
   mounted() {

   },

   methods: {

   },
};
</script>
