<template>
  <v-container fluid class="pa-0">
    <v-card class="pa-5 px-7 rounded-lg shadow">
      <v-row justify="center" justify-sm="start">
        <v-col cols="11" sm="6" lg="4" xl="3" v-for="(reservation, index) in reservations" :key="index">
          <v-card class="shadow-none gray-border mt-5 position-relative pa-5">
            <v-badge  class="absolute-center-x " style="transform: scale(2);" large :size="60" color="primary" avatar  overlap>
              <template v-slot:badge>
                <v-avatar  :size="60">
                  <img :height="60" src="@/assets/img/svg/appointment.svg" alt="" />
                </v-avatar>
              </template>
            </v-badge>
            <div class="py-0 subtitle-1 font-weight-bold">
              <span> {{ reservation.booking_date || "-" | capitalize }}</span>
            </div>
            <div class="py-0 gray6--text subtitle-2">
              {{ $t('branch') }} : <span class="gray1--text"> {{ reservation.branch_name || "-" | capitalize }}</span>
            </div>
            <div class="py-0 gray6--text subtitle-2">
              {{ $t('services') }} : <span class="gray1--text"> {{ reservation.services_names || "-" | capitalize
              }}</span>
            </div>
            <div class="py-0 primary--text subtitle-2 font-weight-bold">
              {{ $t('paid') }} : <span> {{ reservation.total_with_vat || 0 | float }} {{ $t('sar') }}</span>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" v-if="!isLoading && reservations.length == 0">
          <v-alert type="info" outlined dense>
            {{ $t('ops, no appointments here') | capitalize }}
          </v-alert>
        </v-col>
        <v-col cols="12" v-if="!isLoading" class="d-flex justify-center">
          <div class="appointment-btn" v-ripple @click="$router.push('/booking')">
            <div class="px-5">{{ $t('request booking') }}</div>
            <img src="@/assets/img/svg/appointmentWhite.svg" height="50" alt="">
          </div>
        </v-col>
        <v-col v-if="isLoading">
            <AnimatedLoading :isLoading="isLoading" />
        </v-col>
      </v-row>


    </v-card>


  </v-container>
</template>
 
<script>
import AnimatedLoading from '@/components/ui/AnimatedLoading.vue';
export default {
  name: "ReservationsPage",

  components: {
    AnimatedLoading
  },

  data: () => ({
    title: 'my reservations',
    clients: [],
    isLoading: false,
    renewDialog: false,
    selectedCompany: null,
    newCompanyDialog: false,
    reservations: [],
  }),
  head() {
    return {
      title: this.$i18n.t(this.title),

    }
  },
  mounted() {
    this.getData();
  },
  watch: {
    //  $route() {;
    //    this.getData();
    //  },
  },
  methods: {
    getData() {
      this.isLoading = true;
      this.$api.GET_METHOD(`get_my_bookings`).then((response) => {
        this.isLoading = false;
        if (response.check) {
          this.reservations =  response.data.bookings || [];
        } else {
          this.$api.ShowModal('error', response.msg);
        }
      })
    },
  },

}
</script>
 

<style lang="scss" >
.companyCard {
  height: 160px;
  overflow: hidden !important;
  padding: 0 !important;

  @media screen and (max-width: 1263px) {
    height: auto;
    overflow: visible;
    //     padding: 3rem !important;
  }

  .companyCardControl {
    height: 160px;

    @media screen and (max-width: 1263px) {
      height: auto;
    }
  }

  .companyCardSheet {
    height: 180px;
    padding: 2rem 1.5rem !important;

    @media screen and (max-width: 1263px) {
      padding: 3rem 4.5rem !important;
    }

    @media screen and (max-width: 580px) {
      padding: 2rem 2.5rem !important;
    }

  }
}
</style>