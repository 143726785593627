<template>
  <section id="about-section" fluid>
    <v-container class="px-10 mt-16 py-16" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12">
          <div class="text-h4 text-center text-md-start font-weight-bold position-relative">
            <v-divider class="title-divider" />
            {{ $t('FAQs') | capitalize }}
          </div>


          <v-expansion-panels>
            <v-expansion-panel v-for="(question) in $store.state.common_questions" :key="question.id">
              <v-expansion-panel-header class="light mt-3">
                {{ question.question | capitalize }}
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pa-3  gray-border">
                <span v-html="question.answer" />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>


export default {
  name: "FAQPage",

  components: {

  },

  data: () => ({
    data: null,
    title: 'FAQs',
    metaTitle: null,
    metaDescription: null,
    isLoading: false,
  }),
  head() {
    return {
      title: this.$i18n.t(this.title),

    }
  },
  mounted() {
    // this.getData()
  },
  watch: {
    $route() {
      // this.getData();
    },
  },
  methods: {
    // getData() {
    //   this.$store.state.splashScreen = true;
    //   this.$api.GET_METHOD(`home`).then((response) => {
    //     this.$store.state.splashScreen = false;
    //     if (response.check) {
    //       this.$store.commit("SET_DEFAULTS_DATA", response.data);
    //     }
    //   })
    // },


  },

}
</script>

<style scoped lang="scss">
#about-section {
  min-height: 100vh;
  position: relative;
  display: flex;
}
</style>
