<template>
  <v-card flat>
    <div class="text-h5 font-weight-bold ">
      {{ $t('filter') | capitalize }}
    </div>
    <GenericInput type="select" background-color="white" placeholder="departments" :lookups="departments"
      selected_label="name" selected_prop="id" name="department_id" :value="filter.department_id"
      @input="filter.department_id = $event" :required="true" :multi="true" :isLoading="isLoading" :cols="[12, 12, 12]">
    </GenericInput>
    <v-divider></v-divider>

    <div class="text-h5 mt-5 font-weight-bold ">
      {{ $t('price') | capitalize }}
    </div>


    <v-range-slider v-model="filter.range" :max="10000" :min="0" hide-details class="align-center mb-5">
      <template v-slot:prepend>
        <v-text-field dense :value="filter.range[0]" class="mt-0 pt-0" solo background-color="light" hide-details single-line type="number" style="width: 60px"
          @change="$set(filter.range, 0, $event)"></v-text-field>
      </template>
      <template v-slot:append>
        <v-text-field dense :value="filter.range[1]" class="mt-0 pt-0" solo background-color="light" hide-details single-line type="number" style="width: 60px"
          @change="$set(filter.range, 1, $event)"></v-text-field>
      </template>
    </v-range-slider>



    <v-divider></v-divider>
    <v-expansion-panels v-model="panel"  multiple>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="text-h5 font-weight-bold ">
            {{ $t('departments') | capitalize }}
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div v-for="(department, index) in departments" :key="index" class="py-2 px-4 d-flex justify-space-between">
            <div>
              {{ department.name | capitalize }}
            </div>
            <div>
              ({{ department.product_count || 0 }})
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-divider></v-divider>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="text-h5 font-weight-bold ">
            {{ $t('categories') | capitalize }}
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div v-for="(category, index) in categories" :key="index" class="py-2 px-4 d-flex justify-space-between">
            <div>
              <v-checkbox v-model="filter.categories_id" :value="category.id" hide-details>
                <template v-slot:label>
                  <label>
                    {{ category.name | capitalize }}
                  </label>
                </template>
              </v-checkbox>
            </div>
            <div>
              ({{ category.product_count || 0 }})
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-divider></v-divider>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="text-h5 font-weight-bold ">
            {{ $t('brands') | capitalize }}
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div v-for="(brand, index) in brands" :key="index" class="py-2 px-4 d-flex justify-space-between">
            <div>
              <v-checkbox v-model="filter.brands_id" :value="brand.id" hide-details>
                <template v-slot:label>
                  <label>
                    {{ brand.name | capitalize }}
                  </label>
                </template>
              </v-checkbox>
            </div>
            <div>
              ({{ brand.product_count || 0 }})
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

  </v-card>
</template>


<script>
import GenericInput from './GenericInput.vue';
export default {
  name: "FilterCard",
  props: {

  },
  data: () => ({
    panel: [0, 1, 2],
    departments: [
      {
        id: 0,
        name: 'كل المنتجات ', product_count: 100
      },
      {
        id: 1,
        name: 'عناية بالبشرة', product_count: 258
      },
      {
        id: 2,
        name: ' العناية بالشعر', product_count: 604
      },
      {
        id: 3,
        name: 'العناية باللحية ', product_count: 404
      },
      {
        id: 4,
        name: ' أدوات و مجموعات', product_count: 505
      },
      {
        id: 5,
        name: 'ماركات', product_count: 75
      },

    ],
    filter: {
      department_id: [0],
      categories_id: [0],
      brands_id: [0],
      range: [0,10000],
    },
    categories: [
      {
        id: 0,
        name: '  الكل', product_count: 100
      },
      {
        id: 1,
        name: ' بروان', product_count: 258
      },
      {
        id: 2,
        name: 'جارنيير', product_count: 604
      },
      {
        id: 3,
        name: 'كلين أند كلير', product_count: 404
      },
      {
        id: 4,
        name: 'ليلافيكس', product_count: 505
      },
      {
        id: 5,
        name: 'كانتو', product_count: 75
      },

    ],
    brands: [
      {
        id: 0,
        name: '  الكل', product_count: 100
      },
      {
        id: 1,
        name: ' بروان', product_count: 258
      },
      {
        id: 2,
        name: 'جارنيير', product_count: 604
      },
      {
        id: 3,
        name: 'كلين أند كلير', product_count: 404
      },
      {
        id: 4,
        name: 'ليلافيكس', product_count: 505
      },
      {
        id: 5,
        name: 'كانتو', product_count: 75
      },

    ],
  }),
  computed: {

  },
  components: {
    GenericInput
  },
  watch: {},
  mounted() {

  },
  beforeDestroy: function () {

  },
  methods: {

  },
};
</script>



<style scoped lang="scss">

</style>
