<template>
  <v-container fluid class="pa-5 mt-15" style="min-height: 100vh;">
    <v-row>
      <!-- Steps -->

      <v-col cols="12" lg="2" class="mt-5">
        <v-stepper v-model="step" class="shadow-none d-flex justify-center d-lg-block "
          :alt-labels="!($vuetify.breakpoint.name === 'xl' || $vuetify.breakpoint.name === 'lg')">
          <v-stepper-step class="py-0" :complete="step > 1" step="1">
            <div class="d-none d-sm-flex align-center w-100">
              <img src="@/assets/img/svg/active_services.svg" alt="services">
              <div class="mx-2" :class="step >= 1 ? 'primary--text' : 'black--text'"> {{ $t('services') | capitalize }}
              </div>
            </div>
          </v-stepper-step>
          <v-divider vertical v-if="($vuetify.breakpoint.name === 'xl' || $vuetify.breakpoint.name === 'lg')"
            class="vertical-hr" />
          <v-stepper-step class="py-0" :complete="step > 2" step="2">
            <div class="d-none d-sm-flex align-center w-100">
              <img src="@/assets/img/svg/calendar.svg" v-if="!(step >= 2)" alt="date">
              <img src="@/assets/img/svg/active_calendar.svg" v-else alt="date">
              <div class="mx-2" :class="step >= 2 ? 'primary--text' : 'black--text'"> {{ $t('date') | capitalize }}
              </div>
            </div>
          </v-stepper-step>
          <v-divider vertical v-if="($vuetify.breakpoint.name === 'xl' || $vuetify.breakpoint.name === 'lg')"
            class="vertical-hr" />
          <v-stepper-step class="py-0" :complete="step > 3" step="3">
            <div class="d-none d-sm-flex align-center w-100">
              <img src="@/assets/img/svg/time.svg" v-if="!(step >= 3)" alt="time">
              <img src="@/assets/img/svg/active_time.svg" v-else alt="time">
              <div class="mx-2" :class="step >= 3 ? 'primary--text' : 'black--text'"> {{ $t('time') | capitalize }}
              </div>
            </div>
          </v-stepper-step>
          <v-divider vertical v-if="($vuetify.breakpoint.name === 'xl' || $vuetify.breakpoint.name === 'lg')"
            class="vertical-hr" />
          <v-stepper-step class="py-0" :complete="step > 4" step="4">
            <div class="d-none d-sm-flex align-center w-100">
              <img src="@/assets/img/svg/summery.svg" v-if="!(step == 4)" alt="summery">
              <img src="@/assets/img/svg/active_summery.svg" v-else alt="summery">
              <div class="mx-2" :class="step == 4 ? 'primary--text' : 'black--text'">{{ $t('summery') | capitalize }}
              </div>
            </div>
          </v-stepper-step>

        </v-stepper>
      </v-col>
      <v-col cols="12" lg="6" v-if="step <= 3">
        <v-col cols="12">
          <div class="text-h5 font-weight-bold mb-5">
            <span v-if="step == 1">{{ $t('choose service type') | capitalize }}....</span>
            <span v-if="step == 2">{{ $t('choose the day you would like to visit us') | capitalize }}....</span>
            <span v-if="step == 3">{{ $t('choose the time you would like to visit us') | capitalize }}....</span>
          </div>
        </v-col>
        <!-- Step 1 -->
        <v-fade-transition hide-on-leave leave-absolute>
          <template v-if="step == 1">
            <v-card color="" class="mb-12" flat v-if="!isLoading">
              <v-row>
                <v-col cols="12" md="6" lg="5">
                  <v-tabs show-arrows
                    :centered="!($vuetify.breakpoint.name === 'xl' || $vuetify.breakpoint.name === 'lg' || $vuetify.breakpoint.name === 'md')"
                    :vertical="($vuetify.breakpoint.name === 'xl' || $vuetify.breakpoint.name === 'lg' || $vuetify.breakpoint.name === 'md')">
                    <v-tabs-slider></v-tabs-slider>

                    <v-tab active-class="activeItem" class="font-weight-bold" :value="category.id"
                      @click="category_id = category.id" v-for="(category) in categories" :key="category.id">
                      <div class="d-flex w-100 justify-between align-center">
                        <div class="d-flex  align-center">
                          <div v-lazy-container="{ selector: 'img' }" class="d-none d-sm-block" :key="category_img_key">
                            <img :height="25" :width="25"
                              :data-src="$store.state.endpointURL + (category_id == category.id ? category.active_icon : category.unactive_icon)"
                              :data-error="require(category_id == category.id ? '@/assets/img/svg/active_white_default_icon.svg' : '@/assets/img/svg/default_icon.svg')"
                              :data-loading="require(category_id == category.id ? '@/assets/img/svg/active_white_default_icon.svg' : '@/assets/img/svg/default_icon.svg')"
                              class="d-block  mi-start-auto" :alt="category.name" />
                          </div>
                          <div class="mx-3">{{ category.name | capitalize }}</div>
                        </div>
                      </div>
                    </v-tab>
                  </v-tabs>
                </v-col>
                <v-col cols="12" md="6" lg="7">
                  <div class="radio_group my-0 rounded-0 py-2" v-for="(service) in filteredServices" :key="service.id"
                    :class="{
                      'primary': selected_services.some(selectedService => selectedService.id == service.id),
                      'white': !selected_services.some(selectedService => selectedService.id == service.id),
                    }" multiple>
                    <v-checkbox hideDetails :value="service"
                      :color="selected_services.some(selectedService => selectedService.id == service.id) ? 'black' : 'primary'"
                      v-model="selected_services">
                      <template v-slot:label>
                        <v-row class="mx-1 d-flex justify-space-between"
                          :class="{ 'font-weight-bold black--text': selected_services.some(selectedService => selectedService.id == service.id) }">
                          <v-col cols="auto">{{ service.name | capitalize }}</v-col>
                          <v-col cols="auto">
                            {{ service.price | float }}
                            <v-tooltip bottom color="">
                              <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                  <v-icon class="mx-1" color="black"
                                    v-if="service.description != null && service.description !== ''">mdi-information</v-icon>
                                </span>
                              </template>
                              <span>{{ service.description || "-" }}</span>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                      </template>
                    </v-checkbox>
                  </div>
                  <v-alert type="error" outlined dense v-if="filteredServices.length == 0" :value="true">
                    {{ $t('ops, no services here') | capitalize }}
                  </v-alert>
                </v-col>
              </v-row>


            </v-card>
            <v-card color="" class="mb-12" flat v-else>
              <v-row>
                <v-col cols="12" md="6" lg="5">
                  <v-skeleton-loader width="100%" height="50" class="my-1" v-for="(item) in 4" :key="item" type="image" />
                </v-col>
                <v-col cols="12" md="6" lg="7">
                  <v-skeleton-loader width="100%" height="50" class="my-1" v-for="(item) in 4" :key="item" type="image" />
                </v-col>
              </v-row>
            </v-card>
          </template>
        </v-fade-transition>
        <!-- Step 2 -->
        <v-fade-transition hide-on-leave leave-absolute>
          <template v-if="step == 2">
            <v-card color="" class="mb-12" flat>
              <v-date-picker color="primary" class="shadow-none gray-border" full-width
                :locale="$vuetify.rtl == true ? 'ar' : 'en-UK'" v-model="form.booking_date" prev-icon="mdi-skip-previous"
                next-icon="mdi-skip-next"></v-date-picker>
            </v-card>
          </template>
        </v-fade-transition>
        <!-- step 3 -->
        <v-fade-transition hide-on-leave leave-absolute>
          <template v-if="step == 3">
            <v-card color="" class="mb-12" flat>

              <div v-for="group in 3" :key="group">
                <div class="subtitle-1 d-flex align-center font-weight-bold">
                  <div inline class="shadow-primary primary pa-2 position-relative rounded-circle"></div>
                  <div class="mx-5" v-if="group == 1"> {{ $t('morning appointments') | capitalize }}</div>
                  <div class="mx-5" v-else-if="group == 2"> {{ $t('afternoon appointments') | capitalize }}</div>
                  <div class="mx-5" v-else> {{ $t('evening appointments') | capitalize }}</div>
                </div>

                <v-row class="my-2">
                  <v-col v-for="time in times.filter(time => time.group_id === group)" :key="time.time_id" cols="auto">
                    <v-btn @click="form.time_id = time.time_id; form.time_title = time.time_title"
                      :color="form.time_id == time.time_id ? 'primary' : 'gray8'" :min-width="120" depressed
                      :outlined="form.time_id != time.time_id">
                      {{ time.time_title }}
                    </v-btn>
                  </v-col>
                </v-row>

              </div>
            </v-card>
          </template>
        </v-fade-transition>


        <v-row class="justify-space-between">
          <v-col cols="auto">
            <v-btn outlined min-width="120" v-if="step != 1" @click="step = --step">
              {{ $t('prev') | capitalize }}
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn color="primary" v-if="step == 2" :loading="isGetTimeLoading" class="d-block mi-start-auto"
              min-width="120" @click="getTimes" depressed>
              {{ $t('get available time') | capitalize }}
            </v-btn>
            <v-btn color="primary" v-if="step <= 3 && step !== 2" :disabled="selected_services.length == 0"
              class="d-block mi-start-auto" min-width="120" @click="step = ++step" depressed>
              {{ $t('next') | capitalize }}
            </v-btn>
            <v-btn color="primary" v-if="step == 4" class="d-block mi-start-auto" min-width="120" @click="save" depressed>
              {{ $t('save') | capitalize }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <!-- Summery -->
      <v-col cols="12" :lg="step == 4 ? 9 : 3" class="mt-2  mt-lg-16" :class="{ 'd-none d-lg-block': step !== 4 }">
        <v-card class="shadow pa-4" v-if="!isLoading">
          <div class="subtitle-1 font-weight-bold mb-2">
            {{ $t('branch') + ' ' + form.branch_name | capitalize }}
          </div>
          <div class="subtitle-1 d-flex gray5--text mb-5">
            <v-col cols="auto" class="px-0 py-0">
              <v-icon size="18">mdi-map-marker </v-icon>
            </v-col>
            <v-col cols="auto" class="py-0">
              {{ form.branch_address }}
            </v-col>
          </div>
          <v-divider class="mx-6  my-2" />
          <div class="subtitle-1 font-weight-bold my-2" v-if="selected_services.length">
            {{ $t('services') | capitalize }}
          </div>
          <div class="subtitle-1 d-flex gray5--text" v-for="(service, index) in selected_services" :key="index">
            <v-col cols="auto" class="px-0 py-0">
              <v-icon size="10">mdi-circle </v-icon>
            </v-col>
            <v-col cols="auto" class="d-flex justify-space-between w-100  py-0">
              <div>{{ service.name | capitalize }}</div>
              <div>{{ service.price | float }}</div>
            </v-col>
          </div>
          <v-divider class="mx-6 my-5" v-if="selected_services.length" />

          <!-- calculations -->
          <div class="subtitle-1 mt-2 d-flex gray5--text">
            <v-col cols="auto" class="px-0 py-0">
              <v-icon size="10">mdi-circle </v-icon>
            </v-col>
            <v-col cols="auto" class="d-flex justify-space-between w-100 py-0">
              <div>{{ $t('total after discount') | capitalize }}</div>
              <div>{{ totalsCalculations.price | float }}</div>
            </v-col>
          </div>
          <div class="subtitle-1 d-flex gray5--text" v-if="discount">
            <v-col cols="auto" class="px-0 py-0">
              <v-icon size="10">mdi-circle </v-icon>
            </v-col>
            <v-col cols="auto" class="d-flex justify-space-between w-100 py-0">
              <div>{{ $t('discount') | capitalize }}</div>
              <div>{{ totalsCalculations.total_discount | float }}</div>
            </v-col>
          </div>
          <div class="subtitle-1 d-flex gray5--text">
            <v-col cols="auto" class="px-0 py-0">
              <v-icon size="10">mdi-circle </v-icon>
            </v-col>
            <v-col cols="auto" class="d-flex justify-space-between w-100 py-0">
              <div>{{ $t('total vat') | capitalize }}</div>
              <div>{{ totalsCalculations.total_vat_after_discount | float }}</div>
            </v-col>
          </div>
          <div class="subtitle-1 font-weight-bold d-flex black--text">
            <v-col cols="auto" class="px-0 py-0">
              <v-icon size="10">mdi-circle </v-icon>
            </v-col>
            <v-col cols="auto" class="d-flex justify-space-between w-100 py-0">
              <div>{{ $t('total with vat') | capitalize }}</div>
              <div>
                <span v-if="discount" class="gray7--text text-decoration-line-through font-weight-regular">{{
                  totalsCalculations.total_with_vat_without_discount | float }}</span>
                {{ computedData.total_price_after_discount_with_vat | float }}
              </div>
            </v-col>

          </div>
          <!-- Date and Time -->
          <v-divider class="mx-6 mt-5" v-if="step >= 2" />
          <div class="subtitle-1 font-weight-bold my-2" v-if="step >= 2">
            {{ $t('date and time') | capitalize }}
          </div>
          <div class="subtitle-1 black--text" v-if="step >= 2">
            <v-row justify="space-between">
              <v-col cols="auto" class="d-flex justify-space-between">
                <v-icon color="primary" size="18">mdi-calendar </v-icon>
                <div class="mx-2">{{ form.booking_date }}</div>
              </v-col>
              <v-col cols="auto" v-if="step >= 3" class="d-flex justify-space-between">
                <v-icon color="primary" size="18">mdi-alarm </v-icon>
                <div class="mx-2">{{ form.time_title }}</div>
              </v-col>
            </v-row>
          </div>

          <!-- coupon -->

          <v-divider class="mx-6 mt-5" />
          <v-col cols="12">
            {{ $t('discount coupon') | capitalize }}
          </v-col>
          <v-row align="center" justify="center">
            <GenericInput :solo="true" type="text" placeholder="discount code" name="coupon" :value="coupon"
              @input="coupon = $event" :required="false" :isLoading="false" :keydownEnter="getCoupon"
              :cols="step == 4 ? [12, 12, 4] : [12, 12, 12]">
            </GenericInput>
            <v-col cols="12" :md="step == 4 ? '4' : 'auto'">
              <v-btn depressed class="d-block ma-auto" :disabled="!(coupon)" :loading="isCouponLoading" @click="getCoupon"
                :height="38" block color="primary">
                {{ coupon ? $t('activate the coupon') : $t('write code to activate') }}
              </v-btn>
            </v-col>
          </v-row>

        </v-card>
        <v-card class="shadow-none pa-4" v-else>
          <v-skeleton-loader width="100%" class="my-1" v-for="(item) in 4" :key="item" type="table-heading" />
        </v-card>

        <v-row class="justify-space-between mt-5" v-if="step == 4">
          <v-col cols="auto">
            <v-btn outlined min-width="120" @click="step = --step">
              {{ $t('prev') | capitalize }}
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn color="primary" v-if="step == 4" :loading="saveIsLoading" class="d-block mi-start-auto" min-width="120"
              @click="save" depressed>
              {{ $t('reservation confirmation') | capitalize }}
            </v-btn>
          </v-col>
        </v-row>

      </v-col>


    </v-row>

  </v-container>
</template>

<script>
import GenericInput from '@/components/ui/GenericInput.vue';



export default {
  name: "IndexPage",

  components: {
    GenericInput
  },

  data: () => ({

     data: null,
    saveIsLoading: false,
    isCouponLoading: false,
    isGetTimeLoading: false,
    title: 'booking',
    metaTitle: null,
    metaDescription: null,
    isLoading: false,
    services: [],
    types: [],
    steps: [],
    categories: [],
    step: 1,
    category_id: null,
    category_img_key: 1,
    selected_services: [],
    branches: [],
    times: [],
    discount_type: null,
    discount: null,
    coupon_data: Object,
    coupon: null,
    form: {
      services_ids: [],
      type_id: 1,
      branch_id: 1,
      booking_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      time_id: null,
      time_title: null,
      branch_name: null,
      branch_address: null,
      discount_code: null,
    }
  }),
  head() {
    return {
      title: this.$i18n.t(this.title),

    }
  },
  mounted() {
    // this.pageMainData();
    if (!this.$store.state.isAuth) {
      this.$router.push(`/login`);
      this.$api.ShowModal('info', this.$i18n.t('please login first'));
    }
    this.getData();

  },
  computed: {
    filteredServices() {
      if (this.services.length > 0 && this.category_id) {
        return this.services.filter(service => service.category_id === this.category_id)
      }
      else {
        return [];
      }
    },
    totalsCalculations() {
      if (this.computedData.new_array) {
        const totalPrice = this.computedData.new_array.reduce((oldValue, newValue) => {
          return Number(+oldValue) + Number(+newValue.price);
        }, 0);
        const totalQuantityPrice = this.computedData.new_array.reduce((oldValue, newValue) => {
          return Number(+oldValue) + Number(newValue.quantity || 1) * Number(newValue.price);
        }, 0);
        const totalQty = this.computedData.new_array.reduce((oldValue, newValue) => {
          return Number(+oldValue) + Number(+newValue.quantity || 1);
        }, 0);
        const total_discount = this.computedData.new_array.reduce((oldValue, newValue) => {
          return Number(+oldValue) + Number(+newValue.total_discount);
        }, 0);

        const total_vat_after_discount = this.computedData.total_vat_after_discount;

        const total_price_after_discount_with_vat = this.computedData.total_price_after_discount_with_vat;
        const total_with_vat_without_discount = +total_price_after_discount_with_vat + +total_discount;
        return {
          quantity: totalQty,
          price: totalPrice,
          total_discount: total_discount,
          total_quantity_price: totalQuantityPrice,
          total_vat_after_discount: total_vat_after_discount,
          total_with_vat_without_discount: total_with_vat_without_discount,
          total_price_after_discount_with_vat: total_price_after_discount_with_vat,
        }
      } else {
        return {
          price: 0,
          quantity: 0,
          total_discount: 0,
          total_quantity_price: 0,
          total_vat_after_discount: 0,
          total_with_vat_without_discount: 0,
          total_price_after_discount_with_vat: 0,
        }
      }
    },
    computedDiscount() {
      return {
        type: this.discount_type || 1,
        value: this.discount || 0,
        discount_amount: 0,
        percent_value: 0,
        total_price: 0,
      }
    },
    computedData() {
      if (this.selected_services.length > 0) {
        console.log('PRICE_CALCULATION', this.$global.PRICE_CALCULATION(this.selected_services, this.computedDiscount));
        return this.$global.PRICE_CALCULATION(this.selected_services, this.computedDiscount)
      } else {
        return {
          total_price_with_discount: 0,
          total_price_without_vat: 0,
          total_quantity: 0,
          total_discount: 0,
          total_vat: 0,
          total_vat_after_discount: 0,
          total_price_after_discount_with_vat: 0,
          new_array: [],
        }
      }
    },

  },
  watch: {
    step() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    coupon() {
      if (!this.coupon) {
        this.discount = null;
        this.discount_type = null;
      }
    },
    $route() {
      // this.getData();
    },
    category_id() {
      this.category_img_key = this.category_img_key + 1
    },
  },
  methods: {
    pageMainData() {

    },
    getData() {
      this.isLoading = true;
      this.services = []
      this.$api.GET_METHOD(`create_booking`).then((response) => {
        this.isLoading = false;
        if (response.check) {


          this.categories = response.data.categorys || [];
          this.services = response.data.services || [];
          this.branches = response.data.branchs || [];


          // default values : 
          this.form.branch_id = response.data.branchs.length > 0 ? response.data.branchs[0].id : null;
          this.form.branch_name = response.data.branchs.length > 0 ? response.data.branchs[0].name : null;
          this.form.branch_address = response.data.branchs.length > 0 ? response.data.branchs[0].address : null;
          // this.form.time_id = response.data.times.length > 0 ? response.data.times[0].time_id : null;
          // this.form.time_title = response.data.times.length > 0 ? response.data.times[0].time : null;
          this.category_id = response.data.categorys.length > 0 ? response.data.categorys[0].id : null;
          this.category_img_key = this.category_img_key + 1
        }
      })
    },
    getTimes() {
      this.isGetTimeLoading = true;
      const IDS = this.selected_services.map(service => service.id);
      this.$api.POST_METHOD(`get_available_times`, { services: IDS, booking_date: this.form.booking_date }).then((response) => {
        this.isGetTimeLoading = false;
        if (response.check) {
          this.times = response.data || [];
          this.form.time_id = response.data.length > 0 ? response.data[0].time_id : null;
          this.form.time_title = response.data.length > 0 ? response.data[0].time_title : null;
          this.step = this.step + 1
        }
      })
    },
    getCoupon() {
      this.isCouponLoading = true;
      this.$api.GET_METHOD(`get_coupon_data/${this.coupon}`).then((response) => {
        this.isCouponLoading = false;
        if (response.check) {
          if (response.data.coupon.coupon_id) {
            this.coupon_data = response.data.coupon
            this.discount = response.data.coupon.discount_value;
            this.discount_type = response.data.coupon.discount_type;
          }
        } else {
          this.discount = null;
          this.discount_type = null;
        }
      })
    },
    save() {
      this.saveIsLoading = true;
      const IDS = this.selected_services.map(service => service.id);
      const responseBody = {
        services: IDS,
        branch_id: this.form.branch_id,
        booking_date: this.form.booking_date,
        start_time: this.form.time_id,
        coupon_code: this.coupon,
      }
      this.$api.POST_METHOD(`make_booking`, responseBody).then((response) => {
        this.saveIsLoading = false;
        if (response.check) {
          this.$router.push('/profile/reservations')
        }
      })
    }

  },

}
</script>


<style lang="scss" scoped>
.activeItem {
  // border-right: 4px solid blue;
  background-color: var(--primary) !important;
  color: var(--white) !important;

  .primary--text {
    color: var(--white) !important;
  }

  &::after {
    width: 5.22px;
    height: 10px;
    content: '';
    right: unset;
    left: 0;
    position: absolute;
    background-color: var(--primary) !important;
    border-radius: 4px 0px 0px 3px;
  }

}

.vertical-hr {
  width: 30px;
  height: 50px;
  display: block;
  margin: 0 2.5rem;
}
</style>