<template>
  <v-container fluid class="pa-0 px-sm-5 px-md-16 my-16">
    <v-row justify="center" v-if="cart.length > 0">
      <v-col cols="12" md="10" lg="8" class="pb-0">
        <v-stepper v-model="step" class="elevation-0 " alt-labels>
          <v-stepper-header>
            <v-stepper-step :step="1" :complete="step > 1" color="primary">
              <span :class="step > 1 ? 'primary--text font-weight-bold' : 'accent--text'">
                {{ $t('cart') | capitalize}}
              </span>
            </v-stepper-step>

            <v-divider
              :style="step > 1 ? { 'border': '6px solid var(--primary) !important', 'background-color': 'var(--primary) !important' } : null">
            </v-divider>

            <v-stepper-step :step="2" :complete="step > 2" color="primary">
              <span :class="step > 2 ? 'primary--text font-weight-bold' : 'accent--text'">{{ $t('order delivery') | capitalize
              }}</span>
            </v-stepper-step>
          </v-stepper-header>
        </v-stepper>
      </v-col>
    </v-row>
    <div v-if="cart.length > 0" class="mt-2">
      <router-view></router-view>
    </div>
    <v-row justify="center" class="my-16" v-if="cart.length == 0">
      <v-col cols="11" class="pt-0 my-16">
        <img src="@/assets/img/svg/empty_cart.svg" class="d-block ma-auto" alt="">
        <div class="font-weight-bold text-h4 text-center my-8">
          {{ $t('your cart is empty') | capitalize }}
        </div>
        <v-btn large class="d-block ma-auto" color="primary" depressed @click="$router.push('/products')">
          {{ $t('go shopping our products') | capitalize }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>


export default {
  name: "CartPage",

  components: {


  },

  data: () => ({
    isLoading: false,
    data: null,
    step: 1,
    title: 'cart',
    metaTitle: null,
    metaDescription: null,
    cart: [
      {
        id: 8,
        uID: 12128,
        brand_id: 1,
        quantity: 1,
        brand_name: 'جارنيير',
        name: "ماسك الفحم الاسود ",
        description: "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. ولذلك يتم استخدام طريقة لوريم إيبسوم لأنها تعطي توزيعاَ طبيعياَ -إلى حد ما- للأحرف عوضاً عن استخدام هنا يوجد محتوى نصي، هنا يوجد محتوى نصي فتجعلها تبدو (أي الأحرف) وكأنها نص مقروء. العديد من برامح النشر المكتبي .",
        image: "upload/products_images/a426be08-a536-4314-a210-fb9fdcc9354d.jpeg",
        small_image: "upload/products_small_images/a426be08-a536-4314-a210-fb9fdcc9354d.jpeg",
        gallery: [
          {
            image: "upload/products_images/a426be08-a536-4314-a210-fb9fdcc9354d.jpeg",
            small_image: "upload/products_small_images/a426be08-a536-4314-a210-fb9fdcc9354d.jpeg",
          },
          {
            image: "upload/products_images/a426be08-a536-4314-a210-fb9fdcc9354d.jpeg",
            small_image: "upload/products_small_images/a426be08-a536-4314-a210-fb9fdcc9354d.jpeg",
          },
          {
            image: "upload/products_images/a426be08-a536-4314-a210-fb9fdcc9354d.jpeg",
            small_image: "upload/products_small_images/a426be08-a536-4314-a210-fb9fdcc9354d.jpeg",
          },
          {
            image: "upload/products_images/a426be08-a536-4314-a210-fb9fdcc9354d.jpeg",
            small_image: "upload/products_small_images/a426be08-a536-4314-a210-fb9fdcc9354d.jpeg",
          },
        ],
        colors: ['red', 'green', 'yellow', 'blue'],
        additional_data: null,
        old_price: 100,
        discount_value: 20,
        price: 80,
      },
      {
        id: 9,
        uID: 12128,
        brand_id: 1,
        quantity: 1,
        brand_name: 'جارنيير',
        name: "ماسك الفحم احمر ",
        description: "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. ولذلك يتم استخدام طريقة لوريم إيبسوم لأنها تعطي توزيعاَ طبيعياَ -إلى حد ما- للأحرف عوضاً عن استخدام هنا يوجد محتوى نصي، هنا يوجد محتوى نصي فتجعلها تبدو (أي الأحرف) وكأنها نص مقروء. العديد من برامح النشر المكتبي .",
        image: "upload/products_images/a426be08-a536-4314-a210-fb9fdcc9354d.jpeg",
        small_image: "upload/products_small_images/a426be08-a536-4314-a210-fb9fdcc9354d.jpeg",
        gallery: [
          {
            image: "upload/products_images/a426be08-a536-4314-a210-fb9fdcc9354d.jpeg",
            small_image: "upload/products_small_images/a426be08-a536-4314-a210-fb9fdcc9354d.jpeg",
          },
          {
            image: "upload/products_images/a426be08-a536-4314-a210-fb9fdcc9354d.jpeg",
            small_image: "upload/products_small_images/a426be08-a536-4314-a210-fb9fdcc9354d.jpeg",
          },
          {
            image: "upload/products_images/a426be08-a536-4314-a210-fb9fdcc9354d.jpeg",
            small_image: "upload/products_small_images/a426be08-a536-4314-a210-fb9fdcc9354d.jpeg",
          },
          {
            image: "upload/products_images/a426be08-a536-4314-a210-fb9fdcc9354d.jpeg",
            small_image: "upload/products_small_images/a426be08-a536-4314-a210-fb9fdcc9354d.jpeg",
          },
        ],
        colors: ['red', 'green', 'yellow', 'blue'],
        additional_data: null,
        old_price: 100,
        discount_value: 20,
        price: 80,
      },
    ]
  }),
  mounted() {
    this.step = this.$route.path.includes('checkout') ? 2 : 1
  },
  watch: {
    $route() {
      this.step = this.$route.path.includes('checkout') ? 2 : 1
    },
  },
  methods: {
    getData() {
      this.isLoading = true;
      this.$api.GET_METHOD(`cart`).then((response) => {
        this.isLoading = false;
        if (response.check) {
          this.cart = response.items
        }
      })
    },
    removeItem(index) {
      this.cart.splice(index, 1);
    },
  },

}
</script>
