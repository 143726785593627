<template>
  <section id="application-section">

    <v-container class="px-lg-0" fluid>
      <v-row justify="space-around">

        <img src="@/assets/img/svg/about-pattern.svg" id="pattern1" alt="">
        <img src="@/assets/img/svg/app-pattern2.svg" id="pattern2" alt="">




        <v-col cols="12" sm="12" md="7" lg="5" xl="6" class="pa-0" style=" z-index: 1;">
          <!-- v-lazy-container="{ selector: 'img'  }" -->
          <!-- <img :data-src="$store.state.endpointURL + $store.state.sections.about_us.image" class="d-block"  height="500" alt="the concept"> -->
          <img src="@/assets/img/svg/app_screen_shoots.svg" height="450" class="d-block ma-auto" alt="the concept">
        </v-col>

        <v-col cols="12" sm="12" md="5" lg="5" xl="5" style=" z-index: 1;">
          <div class="text-h4 my-9 white--text text-center text-lg-start font-weight-bold">
            {{ $t('available now') | capitalize }}
          </div>


          <v-row justify="space-around" class="d-none d-lg-flex" align="center">
            <v-col cols="auto">
              <img src="@/assets/img/svg/qr.svg" :height="150" class="d-block ma-auto" alt="the concept">
            </v-col>
            <v-col cols="auto">
              <img src="@/assets/img/svg/qr.svg" :height="150" class="d-block ma-auto" alt="the concept">
            </v-col>
          </v-row>

          <v-row justify="space-around" align="center">
            <v-col cols="auto">

              <a :href="$store.state.website_basic_data.link_in_app_store" target="_blank">
                <img src="@/assets/img/svg/appStore.svg" height="70" class="d-block ma-auto" alt="the concept">
              </a>
            </v-col>
            <v-col cols="auto">
              <a :href="$store.state.website_basic_data.link_in_google_play" target="_blank">
              <img src="@/assets/img/svg/googleApp.svg" height="70" class="d-block ma-auto" alt="the concept">
              </a>
            </v-col>
          </v-row>


        </v-col>



      </v-row>
    </v-container>
  </section>
</template>


<script>
export default {
  name: "ApplicationSection",
  props: {
  },
  data: () => ({

  }),
  computed: {

  },
  watch: {},
  mounted() {

  },
  beforeDestroy: function () {

  },
  methods: {

  },
};
</script>



<style scoped lang="scss">
#pattern1 {
  position: absolute !important;
  top: -60px;
  left: 0;
  z-index: 0;
}

#pattern2 {
  position: absolute !important;
  bottom: -60px;
  right: 0;
  z-index: 0;
}

#application-section {

  //min-height: 100vh;
  width: 100vw;
  position: relative !important;
  background-color: var(--secondary);
  display: flex;
  align-items: center;
  padding: 5rem 0;

  img {
    position: relative;



  }

  @media screen and (max-width: 991px) {
    img {
      max-height: 350px;
    }
  }

  @media screen and (max-width: 500px) {
    img {
      max-height: 250px;
    }
  }
}

.v-application--is-rtl {
  #application-section {}
}
</style>
