<template>
  <v-card :width="width" :height="height" :class="classes" :color="color"
    class="shadow-sm pt-2 pt-md-0 pa-3 rounded-lg product_card mx-auto"
   >


    <v-img contain :lazy-src="$store.state.endpointURL + product.small_image" v-if="product.small_image ||  product.image" height="250" max-width="320"
    @click="$router.push(`/products/${product.brand_name}/${product.name}/${product.id}`)" :alt="product.name"
      class="rounded-lg overflow-hidden d-block ma-auto cursor_pointer" :src="$store.state.endpointURL + product.image">
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <!-- <v-progress-circular indeterminate color="primary"></v-progress-circular> -->
          <v-skeleton-loader class="mx-auto" max-width="320" type="image"></v-skeleton-loader>
        </v-row>
      </template>
    </v-img>
    <img src="@/assets/img/default.svg" height="250" max-width="320" v-else class="rounded-lg overflow-hidden d-block ma-auto cursor_pointer"  :alt="product.name">

    <v-card-title class="subtitle-1 font-weight-bold d-flex align-center justify-space-between">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" class="d-inline-block text-truncate cursor_pointer" style="max-width: 60%;"   @click="$router.push(`/products/${product.brand_name}/${product.name}/${product.id}`)">
            {{ product.name || "-" | capitalize }}
          </div>
        </template>
        <span> {{ product.name || "-" | capitalize }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" class="d-flex align-center" style="max-width: 40%;">
            <small class="d-inline-block position-relative mx-2 text-truncate">
              -{{ product.brand_name || "-" | capitalize }}
            </small>
          </div>
        </template>
        <span>{{ product.brand_name || "-" | capitalize }}</span>
      </v-tooltip>

    </v-card-title>

    <v-card-text class="py-0">
      <v-row align="center" class="mx-0">
        <v-rating :value="4.5" color="amber" dense half-increments readonly size="14"></v-rating>
        <div class="grey--text ms-4">
          4.5 (413)
        </div>
      </v-row>

      <div class="my-4 text-subtitle-1">
        <span class="text-decoration-line-through gray9--text" v-if="product.old_price"> {{ product.old_price | float }}</span>
        <span class="primary--text font-weight-bold"> {{ product.price | float }} {{ $t('ryal') }}</span>
        <div class="caption">
          {{ $t('including VAT') | capitalize }}
        </div>
      </div>

    </v-card-text>



    <v-card-actions class="py-0 justify-center">
      <v-btn class="rounded-pill" fab small @click="addToWishlist" icon outlined color="error">
        <v-icon v-if="!inWishlist">mdi-heart-outline</v-icon>
        <v-icon v-else>mdi-heart</v-icon>
      </v-btn>
      <v-btn class="rounded-pill" :min-width="200" @click="addToCart" depressed :color="inCart ? 'success' : 'primary'">
        {{inCart ? $t('added to cart')  : $t('add to cart') }}
        <v-icon v-if="inCart" left>mdi-check</v-icon>
        <img v-else src="@/assets/img/svg/cart.svg" alt="">
      </v-btn>
    </v-card-actions>
  </v-card>
</template>


<script>
export default {
  name: "ProductCard",
  props: {
    height: { default: 450 },
    width: { default: 320 },
    classes: { default: null },
    color: { default: 'transparent' },
    product: {Object},
  },
  data: () => ({
    wishlist : false,
    cart : false,
  }),
  computed: {
    inWishlist(){
      return this.wishlist
    },
    inCart(){
      return this.cart
    },
  },
  watch: {},
  mounted() {

  },
  beforeDestroy: function () {

  },
  methods: {
    addToCart(){
      this.cart = !this.cart
    },
    addToWishlist(){
      this.wishlist = !this.wishlist
    },
  },
};
</script>



<style scoped lang="scss">
.product_card {
  @media screen and (max-width: 991px) {
    border: 1px solid rgba(0,0,0,0.05) !important;
  }
}
</style>
