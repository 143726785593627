<template>
  <v-container fluid class="pa-0">
    <div class="blog-banner">
      <v-row align="center" justify="center">
        <v-col class="text-center mt-15" cols="12">
          <div class="text-h3 font-weight-bold ">
            {{ $t('blogs') | capitalize }}
          </div>
          <div class="subtitle-1 font-weight-bold">
            {{
                $t('browse our carefully written articles with a medical background to be more useful to you.') | capitalize
            }}
          </div>
        </v-col>
      </v-row>
    </div>

    <v-container class="mt-5" fluid>
      <v-row justify="center" justify-md="start">
        <v-col cols="11" sm="6" :md="6" :lg="index == 0 ? 8 : 4" v-for="(blog, index) in blogs" :key="index">
          <v-card class="pa-3 blog-card overflow-hidden position-relative gray-border"
            @click="$router.push(`/blogs/${blog.title}/${blog.id}`)" flat>
            <div class="absolute-center-x light">
              <v-img contain :lazy-src="$store.state.endpointURL + blog.image" height="210"
                class="rounded-lg  overflow-hidden d-block ma-auto" :src="$store.state.endpointURL + blog.image">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <!-- <v-progress-circular indeterminate color="primary"></v-progress-circular> -->
                    <v-skeleton-loader class="mx-auto" max-width="320" type="image"></v-skeleton-loader>
                  </v-row>
                </template>
                <template v-slot:default>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <!-- <v-progress-circular indeterminate color="primary"></v-progress-circular> -->
                    <v-skeleton-loader class="mx-auto" max-width="320" type="image"></v-skeleton-loader>
                  </v-row>
                </template>
              </v-img>
            </div>

            <v-card-title class="text-h6 title position-relative primary--text" style="margin-top: 150px;">
              {{ blog.title }}
              {{ blog.title }}
              {{ blog.title }}
              {{ blog.title }}
              {{ blog.title }}
              {{ blog.title }}
              {{ blog.title }}
              {{ blog.title }}
              {{ blog.title }}
              {{ blog.title }}
              {{ blog.title }}
              {{ blog.title }}
              {{ blog.title }}
              {{ blog.title }}
              {{ blog.title }}
              {{ blog.title }}
              {{ blog.title }}
              {{ blog.title }}
              {{ blog.title }}
              {{ blog.title }}
              {{ blog.title }}
              {{ blog.title }}
              {{ blog.title }}
              {{ blog.title }}
            </v-card-title>
            <v-row justify="space-between" class="mt-2">
              <v-col cols="auto" class="d-flex align-center">
                <v-icon size="19">
                  mdi-clock-outline
                </v-icon>
                <div class="caption mx-2">{{ blog.add_date }}</div>
              </v-col>
              <v-col cols="auto" class="d-flex align-center">
                <v-icon size="19">
                  mdi-comment-outline
                </v-icon>
                <div class="caption mx-2">{{ blog.blog_comments_count }} {{$t('comment') | capitalize}}</div>
              </v-col>
              <v-col cols="12" class="py-0">
                <div class="body-1 descreption position-relative px-2 secondary--text">
                  {{ blog.description }}
                  {{ blog.description }}
                  {{ blog.description }}
                  {{ blog.description }}
                  {{ blog.description }}
                  {{ blog.description }}
                  {{ blog.description }}
                </div>
              </v-col>
              <v-col cols="12">
                <v-btn depressed @click="$router.push(`/blogs/${blog.title}/${blog.id}`)" color="primary" class="d-block mi-start-auto rounded-lg">
                  {{ $t('read more') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <v-col cols="11" sm="6" :md="6" :lg="4" v-intersect="onIntersect">
          <v-sheet class="pa-3">
            <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>


  </v-container>
</template>

<script>


export default {
  name: "IndexPage",

  components: {

  },

  data: () => ({
    data: null,
    filterDialog: false,
    title: 'blogs',
    metaTitle: null,
    metaDescription: null,
    isLoading: false,
    blogs: [],
  }),
  head() {
    return {
      title: this.$i18n.t(this.title),

    }
  },
  mounted() {
    this.onIntersect();
  },
  watch: {
    $route() {

    },
  },
  methods: {

    onIntersect() {
      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false
        this.blogs.push(
          {
            "id": 1,
            "title": "أحدث الطرق لعلاج الصلع الوراثي للرجال",
            "description": "لعلاج الصلع عند الرجال بالأدويةتوجد مجموعة من الأدوية التي أثبتت فعاليتها في علاج الصلع، ومن أبرزها الآتي:",
            "image": "/upload/blogs_images/43584b2a-6ea6-4ec3-a90b-68948d40f0a2.jpeg",
            "blog_active": 1,
            "add_date": "2022-11-17 13:33:34",
            "add_user": 1,
            "added_by": "Admin",
            "blog_comments_count": 3,
            "blog_comments": [
              {
                "id": 1,
                "blog_id": 1,
                "comment": "تيست",
                "user_id": 1,
                "user_full_name": "Admin",
                "user_image": "/upload/users_images/57037f5d-4f93-4d2f-b2b4-c4c10bd0ad8e.jpeg",
                "add_date": "2022-11-17 13:42:19"
              },
              {
                "id": 2,
                "blog_id": 1,
                "comment": "تيست",
                "user_id": 1,
                "user_full_name": "Admin",
                "user_image": "/upload/users_images/57037f5d-4f93-4d2f-b2b4-c4c10bd0ad8e.jpeg",
                "add_date": "2022-11-17 13:42:19"
              },
              {
                "id": 3,
                "blog_id": 1,
                "comment": "تيست",
                "user_id": 1,
                "user_full_name": "Admin",
                "user_image": "/upload/users_images/57037f5d-4f93-4d2f-b2b4-c4c10bd0ad8e.jpeg",
                "add_date": "2022-11-17 13:42:30"
              }
            ]
          },
          {
            "id": 1,
            "title": "أحدث الطرق لعلاج الصلع الوراثي للرجال",
            "description": "لعلاج الصلع عند الرجال بالأدويةتوجد مجموعة من الأدوية التي أثبتت فعاليتها في علاج الصلع، ومن أبرزها الآتي:",
            "image": "/upload/blogs_images/43584b2a-6ea6-4ec3-a90b-68948d40f0a2.jpeg",
            "blog_active": 1,
            "add_date": "2022-11-17 13:33:34",
            "add_user": 1,
            "added_by": "Admin",
            "blog_comments_count": 3,
            "blog_comments": [
              {
                "id": 1,
                "blog_id": 1,
                "comment": "تيست",
                "user_id": 1,
                "user_full_name": "Admin",
                "user_image": "/upload/users_images/57037f5d-4f93-4d2f-b2b4-c4c10bd0ad8e.jpeg",
                "add_date": "2022-11-17 13:42:19"
              },
              {
                "id": 2,
                "blog_id": 1,
                "comment": "تيست",
                "user_id": 1,
                "user_full_name": "Admin",
                "user_image": "/upload/users_images/57037f5d-4f93-4d2f-b2b4-c4c10bd0ad8e.jpeg",
                "add_date": "2022-11-17 13:42:19"
              },
              {
                "id": 3,
                "blog_id": 1,
                "comment": "تيست",
                "user_id": 1,
                "user_full_name": "Admin",
                "user_image": "/upload/users_images/57037f5d-4f93-4d2f-b2b4-c4c10bd0ad8e.jpeg",
                "add_date": "2022-11-17 13:42:30"
              }
            ]
          },
          {
            "id": 1,
            "title": "أحدث الطرق لعلاج الصلع الوراثي للرجال",
            "description": "لعلاج الصلع عند الرجال بالأدويةتوجد مجموعة من الأدوية التي أثبتت فعاليتها في علاج الصلع، ومن أبرزها الآتي:",
            "image": "/upload/blogs_images/43584b2a-6ea6-4ec3-a90b-68948d40f0a2.jpeg",
            "blog_active": 1,
            "add_date": "2022-11-17 13:33:34",
            "add_user": 1,
            "added_by": "Admin",
            "blog_comments_count": 3,
            "blog_comments": [
              {
                "id": 1,
                "blog_id": 1,
                "comment": "تيست",
                "user_id": 1,
                "user_full_name": "Admin",
                "user_image": "/upload/users_images/57037f5d-4f93-4d2f-b2b4-c4c10bd0ad8e.jpeg",
                "add_date": "2022-11-17 13:42:19"
              },
              {
                "id": 2,
                "blog_id": 1,
                "comment": "تيست",
                "user_id": 1,
                "user_full_name": "Admin",
                "user_image": "/upload/users_images/57037f5d-4f93-4d2f-b2b4-c4c10bd0ad8e.jpeg",
                "add_date": "2022-11-17 13:42:19"
              },
              {
                "id": 3,
                "blog_id": 1,
                "comment": "تيست",
                "user_id": 1,
                "user_full_name": "Admin",
                "user_image": "/upload/users_images/57037f5d-4f93-4d2f-b2b4-c4c10bd0ad8e.jpeg",
                "add_date": "2022-11-17 13:42:30"
              }
            ]
          },
          {
            "id": 1,
            "title": "أحدث الطرق لعلاج الصلع الوراثي للرجال",
            "description": "لعلاج الصلع عند الرجال بالأدويةتوجد مجموعة من الأدوية التي أثبتت فعاليتها في علاج الصلع، ومن أبرزها الآتي:",
            "image": "/upload/blogs_images/43584b2a-6ea6-4ec3-a90b-68948d40f0a2.jpeg",
            "blog_active": 1,
            "add_date": "2022-11-17 13:33:34",
            "add_user": 1,
            "added_by": "Admin",
            "blog_comments_count": 3,
            "blog_comments": [
              {
                "id": 1,
                "blog_id": 1,
                "comment": "تيست",
                "user_id": 1,
                "user_full_name": "Admin",
                "user_image": "/upload/users_images/57037f5d-4f93-4d2f-b2b4-c4c10bd0ad8e.jpeg",
                "add_date": "2022-11-17 13:42:19"
              },
              {
                "id": 2,
                "blog_id": 1,
                "comment": "تيست",
                "user_id": 1,
                "user_full_name": "Admin",
                "user_image": "/upload/users_images/57037f5d-4f93-4d2f-b2b4-c4c10bd0ad8e.jpeg",
                "add_date": "2022-11-17 13:42:19"
              },
              {
                "id": 3,
                "blog_id": 1,
                "comment": "تيست",
                "user_id": 1,
                "user_full_name": "Admin",
                "user_image": "/upload/users_images/57037f5d-4f93-4d2f-b2b4-c4c10bd0ad8e.jpeg",
                "add_date": "2022-11-17 13:42:30"
              }
            ]
          },
          {
            "id": 1,
            "title": "أحدث الطرق لعلاج الصلع الوراثي للرجال",
            "description": "لعلاج الصلع عند الرجال بالأدويةتوجد مجموعة من الأدوية التي أثبتت فعاليتها في علاج الصلع، ومن أبرزها الآتي:",
            "image": "/upload/blogs_images/43584b2a-6ea6-4ec3-a90b-68948d40f0a2.jpeg",
            "blog_active": 1,
            "add_date": "2022-11-17 13:33:34",
            "add_user": 1,
            "added_by": "Admin",
            "blog_comments_count": 3,
            "blog_comments": [
              {
                "id": 1,
                "blog_id": 1,
                "comment": "تيست",
                "user_id": 1,
                "user_full_name": "Admin",
                "user_image": "/upload/users_images/57037f5d-4f93-4d2f-b2b4-c4c10bd0ad8e.jpeg",
                "add_date": "2022-11-17 13:42:19"
              },
              {
                "id": 2,
                "blog_id": 1,
                "comment": "تيست",
                "user_id": 1,
                "user_full_name": "Admin",
                "user_image": "/upload/users_images/57037f5d-4f93-4d2f-b2b4-c4c10bd0ad8e.jpeg",
                "add_date": "2022-11-17 13:42:19"
              },
              {
                "id": 3,
                "blog_id": 1,
                "comment": "تيست",
                "user_id": 1,
                "user_full_name": "Admin",
                "user_image": "/upload/users_images/57037f5d-4f93-4d2f-b2b4-c4c10bd0ad8e.jpeg",
                "add_date": "2022-11-17 13:42:30"
              }
            ]
          },
        )
      }, 1000);
    },
  },

}
</script>


<style lang="scss" scoped>
.blog-banner {
  height: 250px;
  position: relative;
  overflow-x: hidden;
  background-image: url('../../../assets/img/svg/landing-section.svg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  color: var(--white);

  display: flex;
  align-items: center;


}

.blog-card {
  .title {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    padding: 0 1rem;
    transition: .3s;
  }

  .descreption {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    padding: 0 3rem;
    transition: .3s;
  }
}
</style>