<template>
  <v-container style="height: 100vh" class="d-flex align-center justify-center bgError">
    <div>
      <v-row align="center" justify="center">
        <v-col cols="12">
          <div class="text-center font-weight-bold primary--text" style="font-size: 11rem;">404</div>
        </v-col>
        <v-col cols="12" class="text-center">
          <h1>{{ $t('page not found') |capitalize}}</h1>
          <v-btn
            color="primary"
            @click="$router.go(-1)"
            class="mt-10" outlined
            elevation="0"
          >{{ $t('back previous page') |capitalize}}</v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<style scoped lang="scss">
.bgError {
  img {
    margin: auto;
    display: block;
    max-height: 300px;
  }
}
</style>

<script>
export default {
  name: "Error404",
  computed: {},
};
</script>
