<template>
  <v-menu v-model="timePicker" :close-on-content-click="false" :label="labelInline == true ? '' : $i18n.t(label)"
    :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field v-model="input" :solo="solo" outlined :reverse="reverse" :dense="dense" :name="name"
        @keydown.enter="keydownEnter()" :class="classes" :color="color" :background-color="backgroundColor" append-icon="mdi-clock-time-four-outline"
        @click:append="timePicker = true" readonly v-bind="attrs" v-on="on" :placeholder="$i18n.t(placeholder)"
        :disabled="disabled" :rules="
          required == true
            ? $store.state.Settings.requiredRule
            : $store.state.Settings.notRequiredRule
        " :loading="isLoading" class="roundedApp" :hide-details="hideDetails == true ? 'auto' : false">
        <template v-slot:label>
          <label v-if="labelInline == false">
            {{ $t(label) }}
            <span class="error--text" v-if="required == true">*</span>
          </label>
        </template>
        <template v-slot:append-outer>
          <slot name="append-outer" />
        </template>
        <template v-slot:append>
          <slot name="append" />
        </template>
        <template v-slot:prepend-inner>
          <slot name="prepend-inner" />
        </template>
        <template v-slot:prepend>
          <slot name="prepend" />
        </template>
      </v-text-field>
    </template>
    <v-time-picker color="primary" format="ampm" :locale="$vuetify.rtl == true ? 'ar-EG' : 'en-UK'"
      :min="minDate ? minDate : ''" :max="maxDate ? maxDate : ''" v-model="input"
      @input="timePicker = false"></v-time-picker>
  </v-menu>
</template>


<script>
export default {
  name: "TimeInput",
  props: {
    name: { default: null },
    label: { default: null },
    type: { default: null },
    value: { default: null },
    required: { default: false },
    dense: { default: true },
    onChange: { type: Function },
    isLoading: { default: null },
    disabled: { default: null },
    color: { default: "secondary" },
    icon: { default: "" },
    hideDetails: { default: false},
    labelInline: { default: false },
    clearable: { default: false },
    hideImage: { default: false },
    onClickOuter: { type: Function },
    keydownEnter: { type: Function },
    placeholder: { default: null },
    solo: { default: true},
    classes: { default: null },
    dir: { default: null },
    reverse: { default: false },
    isPickerOpened: { default: Function },
    minDate: { default: null },
    maxDate: { default: null },
    backgroundColor:  { default: 'light' },
  },
  data: () => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    datePicker: false,
    timePicker: false,
  }),
  computed: {
    input: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {
    datePicker() {
      this.isPickerOpened(this.datePicker)
    },
    timePicker() {
      this.isPickerOpened(this.timePicker)
    },
  },
  mounted() {

  },
  beforeDestroy: function () {

  },
  methods: {
    checkIsItemDisabled(item) {
      if (this.disableItem) {
        if (item[this.disableItem.key] == this.disableItem.value) {
          return true;
        }
        return false;
      }
      else {
        return false;
      }

    },
  },
};
</script>



<style scoped lang="scss">

</style>
