
import firebase from 'firebase/app'
import 'firebase/firebase-messaging'

var firebaseConfig = {
  // apiKey: "AIzaSyChPFIhTQ9iGZWeUUSAdZ_PtdbPpcA-V0w",
  // authDomain: "the-concept-sa.firebaseapp.com",
  // projectId: "the-concept-sa",
  // storageBucket: "the-concept-sa.appspot.com",
  // messagingSenderId: "507970016210",
  // appId: "1:507970016210:web:7fc4bc68ef00d36ad72e0b",
  // measurementId: "G-ST58QC6ZB5"
  apiKey: "AIzaSyCn2WiGS_hc22gTS4u3rgLZ-BZvQgeUKQ0",
  authDomain: "bamboo-autumn-379909.firebaseapp.com",
  projectId: "bamboo-autumn-379909",
  storageBucket: "bamboo-autumn-379909.appspot.com",
  messagingSenderId: "304236742777",
  appId: "1:304236742777:web:1ff5e40edbae1abbd1eca8",
  measurementId: "G-628HWEEJ66"
}

firebase.initializeApp(firebaseConfig)

export default firebase.messaging()