<template>
   <section id="auth-page">


      <v-container fluid class="mt-16 mt-md-5">
         <v-row justify="center">
            <v-col cols="12" md="6" sm="5" order="1" order-sm="2" class="pa-0 d-none d-md-block col-bg"
               :style="{ 'min-height': $store.state.windowSize.y + 'px' }" v-lazy-container="{ selector: 'img' }">
               <img :data-src="$store.state.endpointURL + $store.state.website_basic_data.main_logo"  class="absolute-center" :height="133.52" />
            </v-col>
            <v-col cols="12" md="6" sm="12" order="2" order-sm="1"
               class="white w-100 pa-7 p-md-3 d-flex align-center form-bg">
               <v-card elevation="0" class="pa-5 mt-2 form-card">
                  <v-form ref="form" class="pb-3 px-4" v-model="valid" lazy-validation>

   
                     <v-card-title class="text-h5 font-weight-bold ma-auto d-block" :class="viewOTP?'success--text' :''">
                        <div v-if="!viewNewPassword">{{  viewOTP? $t('enter code') :$t('forget password') |capitalize }}</div>
                        <div v-if="!viewNewPassword" class="caption gray2-text">{{viewOTP?  $t('a code sent to your mobile') : $t('a code will be sent to your mobile') |capitalize}}</div>
                        <div v-if="viewNewPassword">{{  $t('enter new password')  |capitalize }}</div>
                     </v-card-title>

                     <v-row>
                        <v-col cols="12" sm="12" class="py-0" v-if="!viewOTP && !viewNewPassword">
                           <v-col cols="12" sm="12">
                              <label>{{ $t('mobile') }}*</label>
                              <v-text-field hide-details="auto" name="mobile" @keydown.enter="forget()" outlined background-color="white"
                                 class="mt-4 rounded-sm" filled required color="primary" :rules="$store.state.requiredRule"
                                 v-model="form.mobile" dense rounded>
                              </v-text-field>
                           </v-col>
   
                        </v-col>
   
                        <v-col cols="12" v-if="!viewOTP && !viewNewPassword" >
                           <v-btn color="primary" :disabled="!(form.mobile)" :min-width="180" :height="50"  @click="forget" 
                           class="shadow subtitle-1 font-weight-bold mx-auto d-block" :loading="isLoading == true">
                              {{ $t('send to your mobile') }}</v-btn>
                        </v-col>
                        
                        
                        <!-- OTP -->
                        <v-col cols="12"  class="py-0" v-if="viewOTP">
                           <v-col cols="12" sm="12" class="py-2">
                              <label>{{ loginMobile ? loginMobile :$t('mobile') }}</label>
                              <v-text-field hide-details="auto" v-if="!loginMobile"  name="mobile" outlined background-color="white"
                                 class="rounded-sm" filled required color="primary" :rules="$store.state.requiredRule"
                                 v-model="form.mobile" dense rounded>
                              </v-text-field>
                           </v-col>
                           <v-col cols="12" sm="12" class="py-2">
                              <!-- <label>{{ $t('OTP') }}</label> -->
                              <v-otp-input  v-model="otp"  :key="otpKey" :length="4" type="number"    :disabled="loading"
                              @finish="onFinish"   />
                           </v-col>
                           </v-col>
   
                           <v-col cols="12" sm="12" class="py-0" v-if="viewNewPassword">
                              <v-col cols="12" sm="12">
                                 <label>{{ $t('new password') }}*</label>
                                 <v-text-field hide-details="auto" name="mobile" @keydown.enter="changePassword()" outlined background-color="white"
                                 class="mt-4 rounded-sm" filled required color="primary" :rules="$store.state.requiredRule"
                                    v-model="new_password" dense rounded>
                                 </v-text-field>
                              </v-col>
                              
                           </v-col>
                           
                           <v-col cols="12" v-if="viewNewPassword" >
                              <v-btn color="primary" :disabled="!(form.mobile &&  new_password)" :min-width="180" :height="50"  @click="changePassword" 
                              class="shadow subtitle-1 font-weight-bold mx-auto d-block" :loading="isLoading == true">
                                 {{ $t('save new password') }}</v-btn>
                           </v-col>
                     </v-row>
                     </v-form>
               </v-card>
            </v-col>
         </v-row>
      </v-container>
   </section>
</template>
 
<script>

export default {
   name: "ForgetPage",
   components: {

   },

   data: () => ({
      data: null,
      title: 'forget password',
      isLoading: false,
      showPass: false,
      valid: false,
      viewNewPassword: false,
      viewOTP: false,
      otp: null,
      loginMobile: null,
      new_password: null,
      responseMessage: 'please activate and login',
      form: {
         mobile: null,
      }
   }),
   head() {
      return {
         title: this.$i18n.t(this.title),
      }
   },
   mounted() {
      if(this.$route.query.mobile){
      this.loginMobile =this.$route.query.mobile
      this.viewOTP = true
    }
      this.passwordRule = [
         v => !!v || this.$t('Password is required'),
         v => (v && v.length >= 5) || this.$t('Password must be greater than 5 characters'),
      ]
      this.confirmPasswordRule = [
         v => !!v || this.$t('Password is required'),
         v => (v && v == this.newPassword) || this.$t('Password not match'),
      ]
   },
   methods: {
      forget() {
         this.$refs.form.validate()
         if (this.valid) {
            this.isLoading = true;
            this.$api.POST_METHOD(`forget_password`, this.form).then((response) => {
               this.isLoading = false;
               if (response.check) {
                  this.responseMessage = response.msg
                  this.loginMobile = response.data.mobile
                  this.viewOTP = true;
               }else{
                  this.$api.ShowModal('error', response.msg);
               }
            })
         } else {
            this.$api.ShowModal('warning', this.$i18n.t('all field is required'));
         }


      },
      onFinish() {
         if (this.otp) {
            this.viewOTP = false
            this.viewNewPassword = true
         }
      },
      changePassword() {
         this.$refs.form.validate()
         if (this.valid) {
            this.isLoading = true;
            const requestBody = {
               mobile : this.form.mobile,
               otp_token : this.otp,
               new_password : this.new_password,
            }
            this.$api.POST_METHOD(`change_password`, requestBody).then((response) => {
               this.isLoading = false;
               if (response.check) {
                  this.$api.SET_AUTH(response.data);
                  this.$api.ShowModal('success', response.msg);
                  this.$router.push('/')
               }else{
                  this.$api.ShowModal('error', response.msg);
               }
            })
         } else {
            this.$api.ShowModal('warning', this.$i18n.t('all field is required'));
         }

      }
   },

}
</script>
 

<style lang="scss" scoped>
.col-bg {
   background-image: url('../../../assets/img/png/auth_bg.png');
   // background-repeat: no-repeat;
   background-attachment: fixed;
   position: relative;
   overflow: hidden;
   // background-position: center;
}

.form-bg {
   @media screen and (max-width: 960px) {
      background-image: url('../../../assets/img/png/auth_bg.png');
      background-attachment: fixed;
      position: relative;
      background-repeat: initial;
      background-position: center;
   }

}

.form-card {
   background: rgba(255, 255, 255, 0.8);
   backdrop-filter: blur(12.5px);
}

.or {
   position: absolute;
   left: 50%;
   right: 50%;
   top: 1.2rem;
   width: 40px;
   text-align: center;
   font-weight: bold;
}

.google {
   border: 1px solid #d2d1d4;
   text-transform: initial;

   img {
      height: 30px;
   }
}
</style>