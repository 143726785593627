<template>
  <v-container fluid class="pa-0">
    <div class="product-banner">
      <v-row align="center" justify="center">
        <v-col class="text-center mt-15" cols="12">
          <div class="text-h3 font-weight-bold ">
            {{ $t('our products') | capitalize }}
          </div>
          <div class=" text-h6 font-weight-bold">
            {{ $t('browse our best carefully selected products to become distinguished in front of all.') | capitalize
            }}
          </div>
        </v-col>
      </v-row>
    </div>
    <v-container class="my-2">
      <v-row >
        <v-col cols="12" sm="11" md="5" lg="6" xl="5" class="my-2">
          <v-card flat>
            <v-row align="center">

              <v-col cols="12" md="12" lg="3" order="2" order-lg="1">

                <v-row>
                  <v-col cols="4" md="4" lg="12" v-for="(img, index) in product.gallery" :key="index">
                    <v-img contain @click="onboarding = index" style="border : 1px solid #e0e0e0"
                      class="cursor_pointer rounded-lg d-block mx-auto mb-2"
                      :lazy-src="$store.state.endpointURL + img.small_image" max-height="250"
                      :src="$store.state.endpointURL + img.image">
                      <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                          <v-progress-circular indeterminate color="primary"></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>
                </v-row>

              </v-col>
              <v-col cols="12" md="12" lg="9" order="1" order-lg="2">
                <v-window v-model="onboarding" class="rounded-lg " style="border : 1px solid #e0e0e0" vertical>
                  <v-window-item v-for="(img, index) in product.gallery" :key="index">
                    <v-img contain :lazy-src="$store.state.endpointURL + img.small_image" class="" :height="465"
                      :src="$store.state.endpointURL + img.image">
                      <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                          <v-progress-circular indeterminate color="primary"></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-window-item>
                </v-window>

              </v-col>
            </v-row>
          </v-card>

        </v-col>
        <v-col cols="12" sm="11" md="7" lg="6" xl="5" class="my-2">
          <v-card flat>
            <v-row align="center" class="mx-0">
              <v-rating :value="4.5" color="amber" dense half-increments readonly size="14"></v-rating>
              <div class="grey--text ms-4">
                4.5 (413)
              </div>
            </v-row>
            <div class="subtitle-1 my-2 font-weight-bold">
              {{ product.name || "-" | capitalize }}
              <small class="mx-2">- {{ product.brand_name || "-" | capitalize }}</small>
            </div>

            <div class="my-2 text-subtitle-1">
              <span class="text-decoration-line-through gray9--text"> {{ product.old_price | float }}</span>
              <span class="primary--text font-weight-bold"> {{ product.price | float }} {{ $t('ryal') }}</span>
              <div class="caption">
                {{ $t('including VAT') | capitalize }}
              </div>
            </div>

            <div class="body-1 gray6--text">
              <small> {{ product.description || " " | capitalize }}</small>
            </div>

            <v-divider class="ma-4" />

            <div class="subtitle-1 font-weight-bold mb-4">
              {{ $t('size') | capitalize }}
            </div>

            <v-row justify="center" justify-md="start">
              <v-col cols="auto" class="py-1 px-1" v-for="(item, index) in 4" :key="index">
                <v-btn :color="activeSize == index ? 'primary font-weight-black' : 'gray8'" @click="activeSize = index"
                  outlined class="rounded-pill" :min-width="120">80x120 سم</v-btn>
              </v-col>
            </v-row>


            <div class="subtitle-1 font-weight-bold my-4">
              {{ $t('colors') | capitalize }}
            </div>

            <v-row align="center" justify="center" justify-md="start">
              <v-col cols="auto" class="py-1 px-1" v-for="(color, index) in product.colors" :key="index">
                <v-btn :color="color" @click="activeColor = index" fab height="30" width="30"
                  class=" rounded-pill shadow" :key="index">
                  <v-icon color="white" v-if="activeColor == index" size="16">mdi-check</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-row align="center" justify="center" justify-md="start">
              <v-col cols="auto" class="py-1 px-2" :key="index">
                <div class="subtitle-1 font-weight-bold my-4">
                  {{ $t('quantity') | capitalize }}
                </div>
              </v-col>
              <v-col cols="auto" class="py-1 px-2">
                <div style="border: 1px solid #e3e3e3; width:125px ; height: 37;"
                  class="pa-1 d-flex justify-space-around align-center rounded-pill">
                  <v-btn elevation="0" color="transparent" fab @click="quantity++" x-small>
                    <v-icon color="success">mdi-plus</v-icon>
                  </v-btn>
                  <span class="mx-3"> {{ quantity || 0 }}</span>
                  <v-btn elevation="0" color="transparent" fab @click="quantity > 0 ? quantity-- : 0" x-small>
                    <v-icon color="error">mdi-minus-thick</v-icon>
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="auto" class="py-1 px-2">
                <v-btn class="rounded-pill" :height="37" :width="37" fab icon outlined color="error">
                  <v-icon>mdi-heart-outline</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="auto" class="py-1 px-2 d-md-block d-none">
                <v-btn class="rounded-pill " :min-width="150" :height="37" depressed color="primary">
                  <span class="mx-2">{{ $t('add to cart') }}</span>
                  <img src="@/assets/img/svg/cart.svg" class="mx-2" alt="">
                </v-btn>
              </v-col>
              <v-col cols="auto" class="py-1 d-md-none d-block px-2">
                <v-btn fab :width="37" :height="37" depressed color="primary">
                  <img src="@/assets/img/svg/cart.svg" class="mx-2" alt="">
                </v-btn>
              </v-col>
            </v-row>

            <v-row align="center" justify="center">
              <v-col cols="auto" class="py-1 px-1">
                <v-btn :color="'secondary'" to="/cart" :min-width="200" height="37"
                  class=" rounded-pill shadow">
                  {{ $t('get it now') }}
                </v-btn>
              </v-col>
            </v-row>

          </v-card>
        </v-col>

        <v-col cols="12">
          <v-divider class="my-5"></v-divider>
        </v-col>

        <v-col cols="12" sm="3" md="3" lg="3" xl="auto" class="my-2 d-block justify-center">
          <v-tabs transition="scroll-y-transition" v-model="tab" vertical>
            <v-tab>
              {{ $t('product details') }}
            </v-tab>
            <v-tab>
              {{ $t('customers rate') }}
            </v-tab>

          </v-tabs>
        </v-col>
        <v-col cols="auto" class="d-none d-sm-block">
          <v-divider vertical ></v-divider>
        </v-col>
        <v-col cols="12" sm="8" md="8" lg="8" xl="5" class="my-2">
          <v-tabs-items v-model="tab">
            <v-tab-item>
                <v-row>
                  <v-col cols="12" md="6" lg="3">
                    {{$t('explain')}} :
                  </v-col>
                  <v-col cols="auto" class="d-none d-md-block">
                    <v-divider vertical ></v-divider>
                  </v-col>
                  <v-col cols="12" md="6" lg="8">
                    {{'هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. ولذلك يتم استخدام طريقة لوريم إيبسوم لأنها تعطي توزيعاَ طبيعياَ -إلى حد ما- للأحرف عوضاً عن استخدام هنا يوجد محتوى نصي، هنا يوجد محتوى نصي فتجعلها تبدو (أي الأحرف) وكأنها نص مقروء. العديد من برامح النشر المكتبي .                    '}}
                  </v-col>

                  <v-col cols="12" md="6" lg="3">
                    {{$t('department')}} :
                  </v-col>
                  <v-col cols="auto" class="d-none d-md-block">
                    <v-divider vertical ></v-divider>
                  </v-col>
                  <v-col cols="12" md="6" lg="8">
                    <v-btn color="primary" plain class="text-decoration-underline" text>{{'ادوات'}}</v-btn>
                  </v-col>
                  <v-col cols="12" md="6" lg="3">
                    {{$t('category')}} :
                  </v-col>
                  <v-col cols="auto" class="d-none d-md-block">
                    <v-divider vertical ></v-divider>
                  </v-col>
                  <v-col cols="12" md="6" lg="8">
                    <v-btn color="primary" plain class="text-decoration-underline" text>{{'كريمات'}}</v-btn>
                  </v-col>
                  <v-col cols="12" md="6" lg="3">
                    {{$t('brand')}} :
                  </v-col>
                  <v-col cols="auto" class="d-none d-md-block">
                    <v-divider vertical ></v-divider>
                  </v-col>
                  <v-col cols="12" md="6" lg="8">
                    <v-btn color="primary" plain class="text-decoration-underline" text>{{'جارنيير'}}</v-btn>
                  </v-col>
                </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row justify="center" justify-lg="space-between">


                <!-- Users Comments -->
                <v-col cols="12">
                  <v-card class=" shadow-none  pa-5">
                    <div class="text-h6 font-weight-bold mx-2">
                      {{ $t('add your review') | capitalize }} <small class="gray8--text">({{comments.length}} {{$t('review') | capitalize}})</small>
                    </div>
                    
                    <template v-if="comments.length > 0">
                      <v-card class="shadow-none d-flex align-center justify-center  mt-2"
                        v-for="(comment, index) in comments" :key="index">
                        <!-- <v-avatar size="35" class="rounded-circle ">
                          <v-img v-if="comment.user_image" :src="$store.state.endpointURL + comment.user_image" alt="" />
                          <v-img v-else src="@/assets/img/svg/userAvatar.svg" alt="" />
                        </v-avatar> -->
                        <v-card-title class="subtitle-2  font-weight-bold">
                          <div>
                            {{ comment.username }} <span class="mx-1 font-weight-medium gray8--text"><small>{{
                                comment.add_date | dateEn
                            }}</small></span>
                          </div>
                          <v-card-text class="subtitle-2 gray6--text pa-0">{{ comment.comment }} </v-card-text>
                        </v-card-title>
                      </v-card>
                    </template>
                    <div v-else class="text-center text-h6 gray7--text">
                      {{ $t('no comment found') | capitalize }} 🙁
                    </div>
        
                  </v-card>
                </v-col>


                <v-col cols="12" >
                  <v-card class="light shadow-none  pa-5">
                    <div class="text-h6 font-weight-bold mx-2">
                      {{ $t('comments') | capitalize }}
                    </div>
                    <GenericInput type="textarea" background-color="white" placeholder="write a comment.." name="comment"
                      :value="comment" @input="comment = $event" :required="true" :isLoading="false" :cols="[12, 12, 12]">
                    </GenericInput>
                    <v-col cols="12">
                      <v-btn color="primary" class="d-block rounded-lg mi-start-auto" depressed>
                        {{$t('post your comment')}}
                      </v-btn>
                    </v-col>
                  </v-card>
                </v-col>
        

              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-col>


      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import GenericInput from '@/components/ui/GenericInput.vue';



export default {
  name: "ProductPage",

  components: {
    GenericInput
},

  data: () => ({
    data: null,
    title: 'product',
    tab: 0,
    metaTitle: null,
    metaDescription: null,
    activeSize: 0,
    activeColor: 0,
    quantity: 0,
    product: {
      id: 8,
      uID: 12128,
      brand_id: 1,
      brand_name: 'جارنيير',
      name: "ماسك الفحم الاسود ",
      description: "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. ولذلك يتم استخدام طريقة لوريم إيبسوم لأنها تعطي توزيعاَ طبيعياَ -إلى حد ما- للأحرف عوضاً عن استخدام هنا يوجد محتوى نصي، هنا يوجد محتوى نصي فتجعلها تبدو (أي الأحرف) وكأنها نص مقروء. العديد من برامح النشر المكتبي .",
      image: "upload/products_images/ed353962-8aec-4bb6-8aee-79f589b87d83.png",
      small_image: "upload/products_small_images/a426be08-a536-4314-a210-fb9fdcc9354d.jpeg",
      gallery: [
        {
          image: "upload/products_images/ed353962-8aec-4bb6-8aee-79f589b87d83.png",
          small_image: "upload/products_small_images/a426be08-a536-4314-a210-fb9fdcc9354d.jpeg",
        },
        {
          image: "upload/products_images/ed353962-8aec-4bb6-8aee-79f589b87d83.png",
          small_image: "upload/products_small_images/a426be08-a536-4314-a210-fb9fdcc9354d.jpeg",
        },
        {
          image: "upload/products_images/ed353962-8aec-4bb6-8aee-79f589b87d83.png",
          small_image: "upload/products_small_images/a426be08-a536-4314-a210-fb9fdcc9354d.jpeg",
        },
        {
          image: "upload/products_images/ed353962-8aec-4bb6-8aee-79f589b87d83.png",
          small_image: "upload/products_small_images/a426be08-a536-4314-a210-fb9fdcc9354d.jpeg",
        },
      ],
      colors: ['red', 'green', 'yellow', 'blue'],
      additional_data: null,
      old_price: 100,
      discount_value: 20,
      price: 80,
    },
    onboarding: 0,
    comment: null,
    comments : [
      {
        id : 1,
        add_date : '2022-12-01',
        username : 'احمد صابر',
        user_image : null,
        comment : '2. الأدوية التي تمنع إنتاج هرمون ديهدرو - تيستوستيرون الأدوية التي تمنع إنتاج هرمون ديهدرو - تيستوستيرون مثال عليها دواء الفيناستريد (Finasteride).'
      },
      {
        id : 2,
        add_date : '2022-12-01',
        username : 'مصطفي جميل',
        user_image : null,
        comment : '2. الأدوية التي تمنع إنتاج هرمون ديهدرو - تيستوستيرون الأدوية التي تمنع إنتاج هرمون ديهدرو - تيستوستيرون مثال عليها دواء الفيناستريد (Finasteride).'
      }
    ],
  }),
  head() {
    return {
      title: this.$route.params.product,

    }
  },
  mounted() {
    // this.getData()
    setInterval(() => {
      this.onboarding = this.onboarding == this.product.gallery.length ? 0 : this.onboarding + 1
    }, 2000);
  },
  watch: {
    $route() {

    },
  },
  methods: {
    // getData() {
    //   this.$store.state.splashScreen = true;
    //   this.$api.GET_METHOD(`home`).then((response) => {
    //     this.$store.state.splashScreen = false;
    //     if (response.check) {
    //       this.$store.commit("SET_DEFAULTS_DATA", response.data);
    //     }
    //   })
    // },
    next() {
      this.onboarding = this.onboarding + 1 === this.product.gallery
        ? 0
        : this.onboarding + 1
    },
    prev() {
      this.onboarding = this.onboarding - 1 < 0
        ? this.product.gallery - 1
        : this.onboarding - 1
    },
  },

}
</script>
<style lang="scss" scoped>
.product-banner {
  height: 250px;
  position: relative;
  overflow-x: hidden;
  background-image: url('../../../assets/img/svg/landing-section.svg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  color: var(--white);

  display: flex;
  align-items: center;
}
</style>