<template>
  <v-row justify="center" v-if="cart.length > 0">
    <v-col cols="11" md="6" lg="8" class="pt-0">
      <v-card flat style="border : 1px solid #e0e0e0" class="rounded-lg pa-5">
        <div class="text-h5 font-weight-bold">
          {{ $t('cart') | capitalize }}
        </div>
        <v-divider class="ma-4"></v-divider>

        <v-row align="center" v-for="(product, index) in cart" :key="index" justify="space-around">
          <v-col cols="12" lg="6">
            <v-row align="center">
              <v-col cols="12" lg="6">
                <v-img contain @click="onboarding = index" style="border : 1px solid #e0e0e0"
                  class="cursor_pointer rounded-lg d-block mx-auto mb-2"
                  :lazy-src="$store.state.endpointURL + product.small_image" max-height="250"
                  :src="$store.state.endpointURL + product.image">
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-col>
              <v-col cols="12" lg="6" class="text-center text-lg-start">
                <div class="font-weight-bold subtitle-1">
                  {{ product.name | capitalize }}
                </div>
                <div class="subtitle-1">
                  {{ $t('color') | capitalize }} : {{ 'بني' | capitalize }}
                </div>
                <div class="subtitle-1">
                  {{ $t('size') | capitalize }} : {{ '80x120 سم' | capitalize }}
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="12" lg="3" class="d-flex justify-center">
            <div style="border: 1px solid #e3e3e3; width:125px ; height: 37;"
              class="pa-1 d-flex justify-space-around align-center rounded-pill">
              <v-btn elevation="0" color="transparent" fab @click="product.quantity++" x-small>
                <v-icon color="success">mdi-plus</v-icon>
              </v-btn>
              <span class="mx-3"> {{ product.quantity || 0 }}</span>
              <v-btn elevation="0" color="transparent" fab @click="product.quantity > 1 ? product.quantity-- : 1"
                x-small>
                <v-icon color="error">mdi-minus-thick</v-icon>
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" md="12" lg="3" class="d-flex justify-space-between">
            <div class="font-weight-bold primary--text">
              <span class="text-decoration-line-through gray9--text"> {{ product.old_price | float }}</span>
              {{ product.price | float }} {{ $t('sar') }}
            </div>
            <v-icon @click="removeItem(index)" class="cursor_pointer" color="error">mdi-close</v-icon>
          </v-col>
          <v-col cols="12" class="pt-1">
            <v-divider class="mx-4" v-if="cart.length > index + 1"></v-divider>
          </v-col>
        </v-row>

      </v-card>
      <v-col cols="12" class="px-0">
        <v-btn text plain :height="40" color="gray1" @click="$router.push('/products')">
          <span class="text-decoration-underline"> {{ $t('continue shopping') }}</span>
          <v-icon right v-if="$vuetify.rtl">mdi-arrow-left</v-icon>
          <v-icon right v-else>mdi-arrow-right</v-icon>
        </v-btn>
      </v-col>
    </v-col>
    <v-col cols="11" md="6" lg="4" class="pt-0">
      <v-card flat style="border : 1px solid #e0e0e0" class="rounded-lg  pa-5">
        <v-col cols="12" class="d-flex justify-space-between">
          <div class="font-weight-bold text-h6 text-center">
            {{ $t('totals') | capitalize }}
          </div>
          <div class="font-weight-bold text-h6 text-center">
            {{ 1000 | float }}
          </div>
        </v-col>
        <v-col cols="12" class="d-flex justify-space-between">
          <div class="subtitle-1 text-center">
            {{ $t('total') | capitalize }}
          </div>
          <div class="subtitle-1 text-center">
            {{ 1000 | float }}
          </div>
        </v-col>
        <v-col cols="12" class="d-flex justify-space-between">
          <div class="subtitle-1 text-center">
            {{ $t('delivery') | capitalize }}
          </div>
          <div class="subtitle-1 text-center">
            {{ 1000 | float }}
          </div>
        </v-col>
        <v-divider class="ma-4"></v-divider>
        <div class="subtitle-1 font-weight-bold ">
          <v-row align="center">
            <v-col cols="12">
              {{ $t('discount coupon') | capitalize }}
            </v-col>
            <GenericInput :solo="true" type="text" placeholder="discount code" name="coupon" :value="coupon"
              @input="coupon = $event" :required="false" :isLoading="false" :cols="[12, 12, 8]">
            </GenericInput>
            <v-col cols="12" md="auto">
              <v-btn depressed class="d-block ma-auto" :height="38" block color="primary">
                {{ $t('activate the coupon') }}
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn depressed class="d-block white--text ma-auto" :height="40" block color="gray1"
                @click="$router.push('/cart/checkout')">{{ $t('complete the purchase') }}</v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>


import GenericInput from '@/components/ui/GenericInput.vue';
export default {
  name: "IndexPage",

  components: {
    GenericInput
  },

  data: () => ({
    data: null,
    isLoading: false,
    coupon: null,
    title: 'cart',
    metaTitle: null,
    metaDescription: null,
    cart: [
      {
        id: 8,
        uID: 12128,
        brand_id: 1,
        quantity: 1,
        brand_name: 'جارنيير',
        name: "ماسك الفحم الاسود ",
        description: "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. ولذلك يتم استخدام طريقة لوريم إيبسوم لأنها تعطي توزيعاَ طبيعياَ -إلى حد ما- للأحرف عوضاً عن استخدام هنا يوجد محتوى نصي، هنا يوجد محتوى نصي فتجعلها تبدو (أي الأحرف) وكأنها نص مقروء. العديد من برامح النشر المكتبي .",
        image: "/upload/products_images/fbb7546c-bb60-4b70-8166-df8021a3c4ae.png",
        small_image: "/upload/products_images/fbb7546c-bb60-4b70-8166-df8021a3c4ae.png",
        gallery: [
          {
            image: "/upload/products_images/fbb7546c-bb60-4b70-8166-df8021a3c4ae.png",
            small_image: "/upload/products_images/fbb7546c-bb60-4b70-8166-df8021a3c4ae.png",
          },
          {
            image: "/upload/products_images/fbb7546c-bb60-4b70-8166-df8021a3c4ae.png",
            small_image: "/upload/products_images/fbb7546c-bb60-4b70-8166-df8021a3c4ae.png",
          },
          {
            image: "/upload/products_images/fbb7546c-bb60-4b70-8166-df8021a3c4ae.png",
            small_image: "/upload/products_images/fbb7546c-bb60-4b70-8166-df8021a3c4ae.png",
          },
          {
            image: "/upload/products_images/fbb7546c-bb60-4b70-8166-df8021a3c4ae.png",
            small_image: "/upload/products_images/fbb7546c-bb60-4b70-8166-df8021a3c4ae.png",
          },
        ],
        colors: ['red', 'green', 'yellow', 'blue'],
        additional_data: null,
        old_price: 100,
        discount_value: 20,
        price: 80,
      },
      {
        id: 9,
        uID: 12128,
        brand_id: 1,
        quantity: 1,
        brand_name: 'جارنيير',
        name: "ماسك الفحم احمر ",
        description: "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. ولذلك يتم استخدام طريقة لوريم إيبسوم لأنها تعطي توزيعاَ طبيعياَ -إلى حد ما- للأحرف عوضاً عن استخدام هنا يوجد محتوى نصي، هنا يوجد محتوى نصي فتجعلها تبدو (أي الأحرف) وكأنها نص مقروء. العديد من برامح النشر المكتبي .",
        image: "/upload/products_images/fbb7546c-bb60-4b70-8166-df8021a3c4ae.png",
        small_image: "/upload/products_images/fbb7546c-bb60-4b70-8166-df8021a3c4ae.png",
        gallery: [
          {
            image: "/upload/products_images/fbb7546c-bb60-4b70-8166-df8021a3c4ae.png",
            small_image: "/upload/products_images/fbb7546c-bb60-4b70-8166-df8021a3c4ae.png",
          },
          {
            image: "/upload/products_images/fbb7546c-bb60-4b70-8166-df8021a3c4ae.png",
            small_image: "/upload/products_images/fbb7546c-bb60-4b70-8166-df8021a3c4ae.png",
          },
          {
            image: "/upload/products_images/fbb7546c-bb60-4b70-8166-df8021a3c4ae.png",
            small_image: "/upload/products_images/fbb7546c-bb60-4b70-8166-df8021a3c4ae.png",
          },
          {
            image: "/upload/products_images/fbb7546c-bb60-4b70-8166-df8021a3c4ae.png",
            small_image: "/upload/products_images/fbb7546c-bb60-4b70-8166-df8021a3c4ae.png",
          },
        ],
        colors: ['red', 'green', 'yellow', 'blue'],
        additional_data: null,
        old_price: 100,
        discount_value: 20,
        price: 80,
      },
    ]
  }),
  mounted() {
    // this.getData()
  },
  watch: {
    $route() {

    },
  },
  methods: {
    removeItem(index) {
      this.cart.splice(index, 1);
    },
  },

}
</script>
