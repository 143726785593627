<template>
  <section id="landing-section">
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col sm="12" md="6" class="text-center">
          <div class="hero_col">
            <div class=" white--text font-weight-bold" :class="titleFont">
              {{ $store.state.sections.landing_section.title | capitalize }}
            </div>
            <div class="my-5 white--text" :class="bodyFont" v-html="$store.state.sections.landing_section.description" />
            <v-row class="my-5" justify="center">
              <v-col cols="auto">
                <div class="appointment-btn" v-ripple @click="$router.push(`/booking`)">
                  <div class="px-5">{{ $t('request booking') }}</div>
                  <img src="@/assets/img/svg/appointmentWhite.svg" height="50" alt="">
                </div>
              </v-col>
            </v-row>
          </div>

        </v-col>
      </v-row>
    </v-container>
  </section>
</template>


<script>
export default {
  name: "LandingSection",
  props: {
  },
  data: () => ({

  }),
  computed: {
    titleFont() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 'text-h5';
        case 'sm':
          return 'text-h4';
        case 'md':
          return 'text-h3';
        case 'lg':
        case 'xl':
          return 'text-h3';
        default:
          return '';
      }
    },
    bodyFont() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 'subtitle-2';
        case 'sm':
        case 'md':
          return 'text-h6';
        case 'lg':
        case 'xl':
          return 'text-h5';
        default:
          return '';
      }
    }
  },
  watch: {},
  mounted() {

  },
  beforeDestroy: function () {

  },
  methods: {

  },
};
</script>



<style scoped lang="scss">
#landing-section {
  min-height: 100vh;
  max-width: 100vw;
  position: relative;
  overflow-x: hidden;
  background-image: url('../../assets/img/svg/landing-section.svg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;

  .container {
    min-height: 100vh;
    padding-top: 60px;
    display: flex;
    align-items: center;
    z-index: 5;
    position: relative;

    .dashboard_img_col {
      position: relative;
      max-width: 700px !important;
      display: block;
      margin: auto;
    }



    .text-h3 {
      border-bottom: 4px solid var(--primary) !important;
      width: max-content;
      margin: auto;
      padding: 1.2rem;
    }

    #dashboard_img {
      // max-width: 90vw;
      height: 450px;
    }

    #analysis_img {
      position: absolute;
      bottom: 22px;
      right: -41px;
      height: 210px;

    }

    #form_img {
      position: absolute;
      bottom: -20px;
      left: 147px;
      height: 135px;
    }

    #square_img {
      position: absolute;
      top: -39px;
      left: 155px;
      height: 80px;
    }

    @media screen and (max-width: 500px) {
      .dashboard_img_col {
        position: relative;
        width: 300px !important;
      }

      padding-top: 60px;
      text-align: center;

      #dashboard_img {
        height: 200px;
      }

      #analysis_img {
        bottom: 19px;
        right: 28px;
        height: 86px;
      }

      #form_img {
        bottom: 0px;
        left: 89px;
        height: 55px;
      }

      #square_img {
        top: 34px;
        left: 107px;
        height: 31px;
      }
    }
  }

  #circles {
    position: absolute;
    top: -377px;
    left: -192px;
  }



  #blue_lines {
    position: absolute;
    top: 0;
    right: 0;
    height: 100vh;

    @media screen and (max-width: 991px) {
      left: 40%;
      right: unset;
    }
  }


}

.v-application--is-rtl {
  #circles {
    right: -192px !important;
    left: unset !important;
  }

  #blue_lines {
    right: unset !important;
    left: 0 !important;

    @media screen and (max-width: 991px) {
      left: unset !important;
      right: 40% !important;
    }
  }

  #analysis_img {
    right: -50px !important;
  }

  #form_img {
    left: 142px !important;
  }

  #square_img {
    left: 150px !important;
  }


}
</style>
