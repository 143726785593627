<template>
  <v-row justify="center" v-if="cart.length > 0">
    <v-col cols="11" md="6" lg="8" class="pt-0">
      <v-col cols="12" class="d-none d-md-block">
        <div class="text-h5 font-weight-bold">
          {{ $t('get the product in simple steps') | capitalize }}
        </div>
        <div class="subtitle-2 mt-2 gray7--text">
          {{ $t('choose how you can get products with different payment methods') | capitalize }}
        </div>
      </v-col>


      <!-- personal data -->
      <v-card flat class="rounded-lg pa-5 gray-border">
        <v-row>
          <v-col cols="12" lg="4">
            <div class="text-h5 font-weight-bold">
              {{ $t('personal data') | capitalize }}
            </div>
          </v-col>
          <v-col cols="12" lg="8">
            <v-row>
              <GenericInput :solo="true" type="text" placeholder="" label="username" name="full_name"
                :value="form.full_name" @input="form.full_name = $event" :required="false" :isLoading="false"
                :cols="[12, 12, 8]">
              </GenericInput>
              <GenericInput :solo="true" type="mobile" placeholder="" label="mobile" name="mobile" :value="form.mobile"
                @input="form.mobile = $event" :required="false" :isLoading="false" :cols="[12, 12, 6]">
              </GenericInput>
              <GenericInput :solo="true" type="text" placeholder="+966" label="country code" name="country code"
                :value="form.country_code" @input="form.country_code = $event" :required="false" :isLoading="false"
                :cols="[12, 12, 6]">
              </GenericInput>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
      <!-- how to get the order -->
      <v-card flat class="rounded-lg mt-5 pa-5 gray-border">
        <v-row>
          <v-col cols="12" lg="4">
            <div class="text-h5 font-weight-bold">
              {{ $t('how to get the order') | capitalize }}
            </div>
          </v-col>
          <v-col cols="12" lg="8" class="pb-0">
            <v-radio-group hide-details color="primary" v-model="form.order_type">
              <div class="radio_group">
                <v-radio :value="1">
                  <template v-slot:label>
                    <label :class="{ 'font-weight-bold': form.order_type == 1 }">
                      {{ $t('received from The Concept branch') | capitalize }}
                    </label>
                  </template>
                </v-radio>
              </div>
              <div class="radio_group">
                <v-radio :value="3">
                  <template v-slot:label>
                    <label :class="{ 'font-weight-bold': form.order_type == 2 }">
                      {{ $t('shipping the order home') | capitalize }}
                    </label>
                  </template>
                </v-radio>
              </div>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card>
      <!-- payment method -->
      <v-card flat class="rounded-lg mt-5 pa-5 gray-border">
        <v-row>
          <v-col cols="12" lg="4">
            <div class="text-h5 font-weight-bold">
              {{ $t('payment method') | capitalize }}
            </div>
          </v-col>
          <v-col cols="12" lg="8" class="pb-0">
            <v-radio-group hide-details color="primary" v-model="form.payment_type">
              <div class="radio_group">
                <v-radio :value="1">
                  <template v-slot:label>
                    <label :class="{ 'font-weight-bold': form.payment_type == 1 }">
                      {{ $t('payment in cash') | capitalize }}
                    </label>
                  </template>
                </v-radio>
              </div>
              <div class="radio_group">
                <v-radio :value="2">
                  <template v-slot:label>
                    <label :class="{ 'font-weight-bold': form.payment_type == 2 }">
                      {{ $t('pay with your gift card') | capitalize }}
                      {{ 0 | float }}
                      {{ $t('ryal') | capitalize }}
                    </label>
                  </template>
                </v-radio>
              </div>
              <div class="radio_group">
                <v-radio :value="3">
                  <template v-slot:label>
                    <label :class="{ 'font-weight-bold': form.payment_type == 3 }">
                      {{ $t('payment network') | capitalize }}
                    </label>
                  </template>
                </v-radio>
              </div>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card>
      <v-col cols="12" class="px-0">
        <v-row justify="center" justify-lg="space-between">
          <v-col cols="11" md="6" lg="auto">
            <v-btn text plain block :height="40" color="gray1" @click="$router.push(`/products`)">
              <span class="text-decoration-underline"> {{ $t('continue shopping') }}</span>
              <v-icon right v-if="$vuetify.rtl">mdi-arrow-left</v-icon>
              <v-icon right v-else>mdi-arrow-right</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="11" md="6" lg="4">
            <v-btn depressed class="d-block white--text ma-auto rounded-pill" block large :height="46" color="primary"
            @click="makeOrder" >{{ $t('get it now') }}</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-col>
    <v-col cols="11" md="6" lg="4" class="pt-0">

      <v-card flat class="rounded-lg pa-5 gray-border d-none d-lg-block">
        <div class="text-h5 font-weight-bold">
          {{ $t('cart') | capitalize }}
        </div>
        <v-divider class="ma-4"></v-divider>

        <v-row align="center" v-for="(product, index) in cart" :key="index" justify="space-around">
          <v-col cols="12" lg="4">
            <v-img contain @click="onboarding = index"
              class="cursor_pointer rounded-lg d-block mx-auto mb-2 gray-border"
              :lazy-src="$store.state.endpointURL + product.small_image" max-height="250"
              :src="$store.state.endpointURL + product.image">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
          <v-col cols="12" lg="8" class="text-center text-lg-start">
            <div class="font-weight-bold subtitle-1">
              {{ product.name | capitalize }}
            </div>
            <div class="subtitle-1">
              {{ $t('color') | capitalize }} : {{ 'بني' | capitalize }}
            </div>
            <div class="subtitle-1">
              {{ $t('size') | capitalize }} : {{ '80x120 سم' | capitalize }}
            </div>
            <div class="font-weight-bold primary--text">
              <span class="text-decoration-line-through gray9--text"> {{ product.old_price | float }}</span>
              {{ product.price | float }} {{ $t('sar') }}
            </div>
          </v-col>
        </v-row>

      </v-card>


      <v-card flat class="rounded-lg  my-5 gray-border pa-5">
        <v-col cols="12" class="d-flex justify-space-between">
          <div class="font-weight-bold text-h6 text-center">
            {{ $t('totals') | capitalize }}
          </div>
          <div class="font-weight-bold text-h6 text-center">
            {{ 1000 | float }}
          </div>
        </v-col>
        <v-col cols="12" class="d-flex justify-space-between">
          <div class="subtitle-1 text-center">
            {{ $t('total') | capitalize }}
          </div>
          <div class="subtitle-1 text-center">
            {{ 1000 | float }}
          </div>
        </v-col>
        <v-col cols="12" class="d-flex justify-space-between">
          <div class="subtitle-1 text-center">
            {{ $t('delivery') | capitalize }}
          </div>
          <div class="subtitle-1 text-center">
            {{ 1000 | float }}
          </div>
        </v-col>
        <v-divider class="ma-4"></v-divider>
        <div class="subtitle-1 font-weight-bold ">
          <v-row align="center">
            <v-col cols="12">
              {{ $t('discount coupon') | capitalize }}
            </v-col>
            <GenericInput :solo="true" type="text" placeholder="discount code" name="coupon" :value="coupon"
              @input="coupon = $event" :required="false" :isLoading="false" :cols="[12, 12, 8]">
            </GenericInput>
            <v-col cols="12" md="auto">
              <v-btn depressed class="d-block ma-auto" :height="38" block color="primary">
                {{ $t('activate the coupon') }}
              </v-btn>
            </v-col>

          </v-row>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>


import GenericInput from '@/components/ui/GenericInput.vue';
export default {
  name: "PurchasePage",

  components: {
    GenericInput
  },

  data: () => ({
    data: null,
    coupon: null,
    title: 'cart',
    metaTitle: null,
    metaDescription: null,
    cart: [
      {
        id: 8,
        uID: 12128,
        brand_id: 1,
        quantity: 1,
        brand_name: 'جارنيير',
        name: "ماسك الفحم الاسود ",
        description: "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. ولذلك يتم استخدام طريقة لوريم إيبسوم لأنها تعطي توزيعاَ طبيعياَ -إلى حد ما- للأحرف عوضاً عن استخدام هنا يوجد محتوى نصي، هنا يوجد محتوى نصي فتجعلها تبدو (أي الأحرف) وكأنها نص مقروء. العديد من برامح النشر المكتبي .",
        image: "upload/products_images/a426be08-a536-4314-a210-fb9fdcc9354d.jpeg",
        small_image: "upload/products_small_images/a426be08-a536-4314-a210-fb9fdcc9354d.jpeg",
        gallery: [
          {
            image: "upload/products_images/a426be08-a536-4314-a210-fb9fdcc9354d.jpeg",
            small_image: "upload/products_small_images/a426be08-a536-4314-a210-fb9fdcc9354d.jpeg",
          },
          {
            image: "upload/products_images/a426be08-a536-4314-a210-fb9fdcc9354d.jpeg",
            small_image: "upload/products_small_images/a426be08-a536-4314-a210-fb9fdcc9354d.jpeg",
          },
          {
            image: "upload/products_images/a426be08-a536-4314-a210-fb9fdcc9354d.jpeg",
            small_image: "upload/products_small_images/a426be08-a536-4314-a210-fb9fdcc9354d.jpeg",
          },
          {
            image: "upload/products_images/a426be08-a536-4314-a210-fb9fdcc9354d.jpeg",
            small_image: "upload/products_small_images/a426be08-a536-4314-a210-fb9fdcc9354d.jpeg",
          },
        ],
        colors: ['red', 'green', 'yellow', 'blue'],
        additional_data: null,
        old_price: 100,
        discount_value: 20,
        price: 80,
      },
      {
        id: 9,
        uID: 12128,
        brand_id: 1,
        quantity: 1,
        brand_name: 'جارنيير',
        name: "ماسك الفحم احمر ",
        description: "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. ولذلك يتم استخدام طريقة لوريم إيبسوم لأنها تعطي توزيعاَ طبيعياَ -إلى حد ما- للأحرف عوضاً عن استخدام هنا يوجد محتوى نصي، هنا يوجد محتوى نصي فتجعلها تبدو (أي الأحرف) وكأنها نص مقروء. العديد من برامح النشر المكتبي .",
        image: "upload/products_images/a426be08-a536-4314-a210-fb9fdcc9354d.jpeg",
        small_image: "upload/products_small_images/a426be08-a536-4314-a210-fb9fdcc9354d.jpeg",
        gallery: [
          {
            image: "upload/products_images/a426be08-a536-4314-a210-fb9fdcc9354d.jpeg",
            small_image: "upload/products_small_images/a426be08-a536-4314-a210-fb9fdcc9354d.jpeg",
          },
          {
            image: "upload/products_images/a426be08-a536-4314-a210-fb9fdcc9354d.jpeg",
            small_image: "upload/products_small_images/a426be08-a536-4314-a210-fb9fdcc9354d.jpeg",
          },
          {
            image: "upload/products_images/a426be08-a536-4314-a210-fb9fdcc9354d.jpeg",
            small_image: "upload/products_small_images/a426be08-a536-4314-a210-fb9fdcc9354d.jpeg",
          },
          {
            image: "upload/products_images/a426be08-a536-4314-a210-fb9fdcc9354d.jpeg",
            small_image: "upload/products_small_images/a426be08-a536-4314-a210-fb9fdcc9354d.jpeg",
          },
        ],
        colors: ['red', 'green', 'yellow', 'blue'],
        additional_data: null,
        old_price: 100,
        discount_value: 20,
        price: 80,
      },
    ],
    form: {
      full_name: null,
      mobile: null,
      country_code: null,
      order_type: 1,
      payment_type: 1,
    }
  }),
  head() {
    return {
      title: this.$i18n.t(this.title),

    }
  },
  mounted() {
    // this.getData()

  },
  watch: {
    $route() {

    },
  },
  methods: {
    removeItem(index) {
      this.cart.splice(index, 1);
    },
    makeOrder() {
      this.$api.ShowModal('success', this.$i18n.t('the purchase has been completed successfully'), this.$i18n.t('purchased, one day waiting for you') + ' 2022-10-10');
      this.$router.push('/cart')
    },
  },

}
</script>
