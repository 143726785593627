<template>
  <v-text-field :solo="solo" outlined dense :name="name" :class="classes" :disabled="false" @click:append="onReset"
    :append-icon="value ? 'mdi-close' : icon" 
    :rules="required == true ? $store.state.Settings.requiredNumberRule : $store.state.Settings.notRequiredRule"
    :loading="isLoading" rounded-md :color="color" :background-color="backgroundColor" :hide-details="hideDetails == true ? 'auto' : false"
    :autofocus="autofocus" @blur="(e) => inputStatus(e)" @focus="(e) => inputStatus(e)"
    @keydown.enter="(e) => keydownEnter(e.target.value)" @change="(e) => backValueMethod(e.target.value)"
    :readonly="disabled"
    :value="computedValue">
    <template v-slot:label>
      <label v-if="labelInline == false">
        {{ $t(label) }}
        <span class="error--text" v-if="required == true && label">*</span>
      </label>
    </template>
  </v-text-field>
</template>


<script>
export default {
  name: "FloatInput",
  props: {
    backValueMethod: { type: Function },
    clickEnter: { type: Function },
    value: { default: "" },
    disabled: { default: true },
    disabledDot: { default: false },
    number: { default: false },
    autofocus: { default: false },

    name: { default: null },
    label: { default: null },
    required: { default: false },
    labelInline: { default: false },
    onChange: { type: Function },
    isLoading: { default: null },
    color: { default: "secondary" },
    icon: { default: "" },
    hideDetails: { default: false},
    keydownEnter: { type: Function },
    solo: { default: true},
    classes: { default: null },
    backgroundColor:  { default: 'light' },
  },
  data: () => ({
    maxLength: 24,
    blurred: false,
    isActive: false,
    includeDot: false,
    activeInput: true,
  }),
  computed: {
    computedValue(){
      return this.number == false ? this.$global.DecimalFormatter(+this.value) != 'NaN' ? this.$global.DecimalFormatter(+this.value) : this.$global.DecimalFormatter(Number(0.00)) : this.value
    }

  },
  watch: {
    value() {
      this.includeDot = this.value.includes(".")
    }
  },
  mounted() {
    this.backValueMethod(this.value);
    this.$el.addEventListener("keypress", e => {
      if ((Number(e.key) || e.key == '.' || e.key == '0') && this.disabled == true) {
        this.onInput(e.key)
      }
    });
    this.$el.addEventListener("keydown", e => {
      if (e.code == "Backspace" && this.disabled == true) {
        this.onDelete()
      }
    });
  },
  beforeDestroy: function () {
    this.$el.removeEventListener('click', this.onClick)
  },
  methods: {
    onInput(key) {
      if (!(key == "." && this.value.includes("."))) {
        this.value = (this.value + key).slice(0, this.maxLength);
        this.backValueMethod(this.value);
      }
    },
    onDelete() {
      this.value = this.value.slice(0, this.value.length - 1);
      this.backValueMethod(this.value);
    },
    onReset() {
      this.value = "";
      this.backValueMethod(this.value);
      this.backValueMethod(this.value);
    },
    setCode(code) {
      // console.log("code", code);
      this.backValueMethod(code);
      this.clickEnter(code)
      this.value = code
    },
    inputStatus(e) {
      console.log(e);
      switch (e.type) {
        case 'blur':
          this.activeInput = false
          break;
        case 'focus':
          this.activeInput = true
          break;

        default:
          break;
      }
    }
  },
};
</script>



<style scoped lang="scss">

</style>
