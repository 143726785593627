<template>
  <section id="about-section"  >

    <v-container  class="px-0" fluid>
      <v-row align="center" justify="center">

        <v-col cols="12" sm="12" md="7" lg="6" xl="5" order="2" order-md="1">
          <div class="text-h4 px-10 white--text text-center text-md-start font-weight-bold">
            {{ $store.state.sections.about_us.title | capitalize }}
          </div>
          <div class="my-5 px-10 white--text text-center text-md-start " v-html="$store.state.sections.about_us.description" />
        
          
          <div class="appointment-btn ma-auto ma-md-start" v-ripple @click="$router.push(`/booking`)">
            <div class="px-5">{{ $t('request booking') }}</div>
            <img src="@/assets/img/svg/appointmentWhite.svg" height="50" alt="">
          </div>


        </v-col>

        <v-col cols="auto" sm="auto" md="5" lg="6" xl="6" class="px-0" order="1"  order-md="2" v-lazy-container="{ selector: 'img'  }">
          <img :data-src="$store.state.endpointURL + $store.state.sections.about_us.image" class="d-block mt-3 mi-start-auto" alt="Qawaem">
          <!-- <img src="@/assets/img/svg/aboutImg.svg" height="450" class="d-block mt-3 mi-start-auto" alt="Qawaem"> -->
        </v-col>


      </v-row>
    </v-container>
  </section>
</template>


<script>
export default {
  name: "AboutSection",
  props: {
  },
  data: () => ({

  }),
  computed: {

  },
  watch: {},
  mounted() {

  },
  beforeDestroy: function () {

  },
  methods: {
    
  },
};
</script>



<style scoped lang="scss">
#about-section {
//  min-height: 100vh;
  width: 100vw;
  position: relative;
  background-color:  var(--secondary) ;
  display: flex;
  align-items: center;
  align-items: center;
  padding: 5rem 0;
  img {
    position: relative;



  }

  @media screen and (max-width: 991px) {
    img {
      max-height: 350px;
    }
  }
  @media screen and (max-width: 500px) {
    img {
      max-height: 300px;
    }
  }
}

.v-application--is-rtl {
  #about-section {




  }
}
</style>
