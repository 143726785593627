<template>
  <section id="profile-page" >
    <v-container fluid class="px-7 px-lg-16">

      <v-row justify="center" justify-sm="start" >
        <v-col cols="12" md="3" lg="3" class="px-0 px-md-2">
          <v-card class="py-1 px-0 px-lg-3 shadow rounded-lg">
            <div class="text-h5 mt-3 font-weight-bold">{{$t('personal profile') | capitalize}}</div>
            <v-list  class="overflow-hidden  rounded-lg">

      
              <v-list-item active-class="activeItem" class="mt-3"  link exact :to="`/profile`" color="primary">
                <v-list-item-title class="text-start font-weight-bold primary--text">{{ $t('my profile') | capitalize }}</v-list-item-title>
              </v-list-item>
      

              <v-list-item active-class="activeItem" class="mt-3"  link exact :to="`/profile/reservations`" color="primary">
                <v-list-item-title class="text-start font-weight-bold primary--text">{{ $t('my reservations') | capitalize }}</v-list-item-title>
              </v-list-item>

              <v-list-item active-class="activeItem" class="mt-3"  link exact :to="`/profile/requests`" color="primary">
                <v-list-item-title class="text-start font-weight-bold primary--text">{{ $t('my requests') | capitalize }}</v-list-item-title>
              </v-list-item>


              <v-list-item active-class="activeItem" class="mt-3"   color="error" @click="changeLanguage" >
                <v-list-item-title class="text-start font-weight-bold primary--text">
                  {{ $vuetify.rtl == true ?$t('english') : $t('عربي') | capitalize }}
                </v-list-item-title>
              </v-list-item>

              <v-list-item active-class="activeItem" class="mt-3"   color="error" @click="$api.LOGOUT(true)">
                <v-list-item-title class="text-start font-weight-bold error--text">{{ $t('logout') | capitalize }}</v-list-item-title>
              </v-list-item>


            </v-list>
          </v-card>
        </v-col>
        <v-col cols="12" md="9" lg="9" class="px-0 px-md-2">
          <router-view></router-view>
        </v-col>
      </v-row>
    </v-container>`
  </section>
</template>
 
<script>

export default {
  name: "ProfilePage",

  components: {

  },
  data: () => ({
    data: null,
    title: 'my profile',
  }),
  head() {
    return {
      title: this.$i18n.t(this.title),

    }
  },
  mounted() {

    if(!this.$store.state.isAuth){
      this.$router.push('/')
    }
  },
  watch: {
    $route() {

    },
  },
  methods: {
    changeLanguage() {
      this.$store.dispatch("Settings/changeLanguage");
    },
  },

}
</script>
 

<style lang="scss" scoped>
#profile-page {
  background: var(--white);
  padding-top: 7rem;
  min-height: 100vh;
  width: 100vw;

  //  display: flex;
  //align-items: center;
  .activeItem {
    // border-right: 4px solid blue;
    background-color: var(--primary) !important;
    color: var(--white) !important;
    .primary--text{
      color: var(--white) !important;
    }
    &::after {
      width: 5.22px;
      height: 10px;
      content: '';
      right: unset;
      left: 0;
      position: absolute;
      background-color: var(--primary) !important;
      border-radius: 4px 0px 0px 3px;
    }
  }

}
</style>