<template>
   <section id="auth-page">


      <v-container fluid class="mt-16 mt-md-5">
         <v-row justify="center">
            <v-col cols="12" md="6" sm="5" order="1" order-sm="2" class="pa-0 d-none d-md-block col-bg"
               :style="{ 'min-height': $store.state.windowSize.y + 'px' }" v-lazy-container="{ selector: 'img' }">
               <img :data-src="$store.state.endpointURL + $store.state.website_basic_data.main_logo"  class="absolute-center" :height="133.52" />
            </v-col>
            <v-col cols="12" md="6" sm="12" order="2" order-sm="1"
               class="white w-100 pa-7 p-md-3 d-flex align-center form-bg">
               <v-card id="auth-form" class="pa-5 mt-2 w-100 shadow-none form-card">
                  <v-form ref="form" v-model="valid" lazy-validation>

                     <v-card-title class="text-h5 px-9 font-weight-bold ma-auto d-block"
                        :class="viewOTP ? 'success--text' : ''">
                        <div>{{ viewOTP ? $t('register success') : $t('new register')
                              | capitalize
                        }}</div>
                        <div class="subtitle-1 gray7--text px-3">
                           {{ viewOTP ? $t(responseMessage) : $t('join us') | capitalize }}
                        </div>
                     </v-card-title>

                     <v-col cols="12" sm="12" class="py-0" v-if="viewOTP === false">
                        <v-col cols="12" sm="12" class="py-2">
                           <label class="px-3">{{ $t('mobile') | capitalize }}*</label>
                           <GenericInput type="mobile" name="mobile" :value="form.mobile" :keydownEnter="register"
                              @input="form.mobile = $event" :required="true" :isLoading="isLoading" :cols="[12, 12, 12]">
                           </GenericInput>
                        </v-col>
                        <v-col cols="12" sm="12" class="py-2">
                           <label class="px-3">{{ $t('full name') | capitalize }}*</label>
                           <GenericInput type="text" name="full_name" :value="form.full_name" :keydownEnter="register"
                              @input="form.full_name = $event" :required="true" :isLoading="isLoading" :cols="[12, 12, 12]">
                           </GenericInput>
                        </v-col>
                        <v-col cols="12" sm="12" class="py-2">
                           <label class="px-3">{{ $t('email') | capitalize }}*</label>
                           <GenericInput type="email" name="email" :value="form.email" :keydownEnter="register"
                              @input="form.email = $event" :required="true" :isLoading="isLoading" :cols="[12, 12, 12]">
                           </GenericInput>
                        </v-col>
                        <v-col cols="12" sm="12" class="py-2">
                           <label class="px-3 mb-1">{{ $t('password') | capitalize }}*</label>
                           <GenericInput type="password" name="password" :value="form.password" :keydownEnter="register" 
                           @input="form.password = $event" :required="true" :isLoading="isLoading" :cols="[12, 12, 12]" />
                        </v-col>
                        <v-col cols="12" sm="12" class="py-2">

                           <label class="px-3 mb-1">{{ $t('confirm password') | capitalize }}*</label>
                           <GenericInput type="password" name="password" :value="form.confirm_password" :keydownEnter="register" 
                           @input="form.confirm_password = $event" :required="true" :isLoading="isLoading" :cols="[12, 12, 12]" />

                        </v-col>
                        <v-col cols="12" sm="12" class="py-2">
                           <recaptcha />
                        </v-col>

                        <v-col cols="12" class="py-2">
                           <v-btn color="primary" :min-width="180" :disabled="!valid" :height="48" @click="register"
                              class="shadow subtitle-1 font-weight-bold" :loading="isLoading == true">
                              {{ $t('create new account') }}</v-btn>
                        </v-col>
                        <v-col cols="12" class="d-flex align-center pt-0 py-2">
                           <label class="">{{ $t("have a registered account?") }}</label>
                           <v-btn class="" color="primary" @click="$router.push('login')" text plain>
                              {{ $t('to login, click here') }}
                           </v-btn>
                        </v-col>
                     </v-col>


                     <!-- OTP -->
                     <v-col cols="12" class="py-0" v-if="viewOTP">
                        <v-col cols="12" sm="12" class="py-2">
                           <label class="px-3 mb-1">{{ registeredEmail ? registeredEmail : $t('email') }}</label>
                           <GenericInput type="email"  v-if="!registeredEmail" name="email" :value="form.email" :keydownEnter="register"
                           @input="form.email = $event" :required="true" :isLoading="isLoading" :cols="[12, 12, 12]">
                        </GenericInput>


                        </v-col>
                        <v-col cols="12" sm="12" class="py-2">
                           <!-- <label>{{ $t('OTP') }}</label> -->
                           <v-otp-input v-model="otp" :key="otpKey" :length="4" type="number" :disabled="loading"
                              @finish="onFinish" />
                        </v-col>
                     </v-col>


                  </v-form>
               </v-card>
            </v-col>
         </v-row>
      </v-container>
   </section>
</template>
 
<script>
import GenericInput from '@/components/ui/GenericInput.vue'


export default {
   name: "LoginPage",
   components: {
    GenericInput
},

   data: () => ({
      data: null,
      panel: [],
      title: 'login',
      isLoading: false,
      showPass: false,
      showConfPass: false,
      viewOTP: false,
      otp: null,
      recaptchaToken: null,
      registeredEmail: null,
      responseMessage: 'registered please activate and login',
      form: {
         email: null,
         password: null,
         full_name: null,
         mobile: null,
         confirm_password: null,
         recaptcha_token: null,
      }
   }),
   head() {
      return {
         title: this.$i18n.t(this.title),
      }
   },
   mounted() {
      // this.getRecaptchaToken();
      if (this.$route.query.email) {
         this.registeredEmail = this.$route.query.email
         this.viewOTP = true
      }

   },
   methods: {
      async getRecaptchaToken() {
         try {
            await this.$recaptcha.init()

         } catch (error) {
            this.$api.ShowModal('error', this.$i18n.t('reCaptcha error'));
         }
      },
      async register() {
         this.$refs.form.validate()
         try {
            this.recaptchaToken = await this.$recaptcha.getResponse()
            if (this.recaptchaToken) {
               if (this.valid && this.recaptchaToken) {
                  this.form.recaptcha_token = this.recaptchaToken
                  this.isLoading = true;
                  this.$api.POST_METHOD(`register`, this.form).then((response) => {
                     this.isLoading = false;
                     if (response.check) {
                        this.responseMessage = response.msg
                        this.registeredEmail = response.data[0].email
                        this.viewOTP = true;
                        this.resetRecaptcha();
                     } else {
                        this.$api.ShowModal('error', response.msg);
                     }
                  })
               } else {
                  this.$api.ShowModal('warning', this.$i18n.t('all field is required'));
               }
            }else{
               this.$api.ShowModal('error', this.$i18n.t('reCaptcha error'));
            }


         }
         catch (error) {
            this.$api.ShowModal('error', this.$i18n.t('reCaptcha error'));
         }
      },
      async resetRecaptcha() {
         try {
            await this.$recaptcha.reset()
         } catch (error) {
            this.$api.ShowModal('error', this.$i18n.t('reCaptcha error'));
         }
      },

      onFinish() {
         if (this.otp) {
            this.isLoading = true;
            this.$api.POST_METHOD(`user_activation`, { email: this.form.email, otp_token: this.otp }).then((response) => {
               this.isLoading = false;
               if (response.check) {
                  this.$api.SET_AUTH(response.data);
                  this.$api.ShowModal('success', response.msg);
                  this.$router.push('/')
               } else {
                  this.$api.ShowModal('error', response.msg);
               }
            })
         }

      },

   },
   computed:{
      valid(){
         if(this.form.email && this.form.password && this.form.confirm_password){
            return true;
         }
         else{
            return false;
         }
      }
   },
   beforeDestroy() {
      this.$recaptcha.destroy()
   },
}
</script>
 

<style lang="scss" scoped>
.col-bg {
   background-image: url('../../../assets/img/png/auth_bg.png');
   // background-repeat: no-repeat;
   background-attachment: fixed;
   position: relative;
   overflow: hidden;
   // background-position: center;
}

.form-bg {
   @media screen and (max-width: 960px) {
      background-image: url('../../../assets/img/png/auth_bg.png');
      background-attachment: fixed;
      position: relative;
      background-repeat: initial;
      background-position: center;
   }

}

.form-card {
   background: rgba(255, 255, 255, 0.8);
   backdrop-filter: blur(12.5px);
}

.or {
   position: absolute;
   left: 50%;
   right: 50%;
   top: 1.2rem;
   width: 40px;
   text-align: center;
   font-weight: bold;
}

.google {
   border: 1px solid #d2d1d4;
   text-transform: initial;

   img {
      height: 30px;
   }
}
</style>