// Website Module Routes:-
import HomeModule from "@/views/Website/Home/HomeModule";
import ForgetPassword from "@/views/Website/Auth/ForgetPassword.vue";
import LoginPage from "@/views/Website/Auth/LoginPage.vue";
import RegisterPage from "@/views/Website/Auth/RegisterPage.vue";

import Profile from "@/views/Website/Profile/Profile.vue";
import PersonalData from "@/views/Website/Profile/PersonalData.vue";
import Requests from "@/views/Website/Profile/Requests.vue";
import Reservations from "@/views/Website/Profile/Reservations.vue";

import CartPage from "@/views/Website/Cart/CartPage.vue";
import CartList from "@/views/Website/Cart/CartList.vue";
import Checkout from "@/views/Website/Cart/Checkout.vue";

import WishlistPage from "@/views/Website/WishlistPage.vue";

import BookingPage from "@/views/Website/BookingPage.vue";

import ProductsPage from "@/views/Website/Products/ProductsPage.vue";
import ProductPage from "@/views/Website/Products/ProductPage.vue";

import BlogsPage from "@/views/Website/Blogs/BlogsPage.vue";
import BlogPage from "@/views/Website/Blogs/BlogPage.vue";

// Static Pages
import AboutUSPage from "@/views/Website/StaticPages/AboutUSPage.vue";
import ContactUSPage from "@/views/Website/StaticPages/ContactUSPage.vue";
import DeliveryPage from "@/views/Website/StaticPages/DeliveryPage.vue";
import FAQPage from "@/views/Website/StaticPages/FAQPage.vue";
import GiftPage from "@/views/Website/StaticPages/GiftPage.vue";
import PrivacyPage from "@/views/Website/StaticPages/PrivacyPage.vue";
import ReturnPolicyPage from "@/views/Website/StaticPages/ReturnPolicyPage.vue";
import PersonalPrivacy from "@/views/Website/StaticPages/PersonalPrivacy.vue";
import AccessOptions from "@/views/Website/StaticPages/AccessOptions.vue";
import ThirdPartyWebsites from "@/views/Website/StaticPages/ThirdPartyWebsites.vue";
import InformationPeriod from "@/views/Website/StaticPages/InformationPeriod.vue";
import SensitiveInformation from "@/views/Website/StaticPages/SensitiveInformation.vue";
import Security from "@/views/Website/StaticPages/Security.vue";


export const WebsiteModuleRoutes = [
  // Home

  {
    path: "/",
    name: "HomeModule",
    component: HomeModule,
    meta: {
      title: "home",
    },
  },

  // Auth
  {
    path: "/register",
    name: "Register",
    component: RegisterPage,
    meta: {
      title: "register",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
    meta: {
      title: "login",
    },
  },
  {
    path: "/forget",
    name: "ForgetPassword",
    component: ForgetPassword,
    meta: {
      title: "forget password",
    },
  },
  // Profile
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: {
      title: "my profile",
    },
    children: [
      {
        path: "/",
        name: "PersonalData",
        component: PersonalData,
        meta: {
          title: "profile",
        },
      },
      {
        path: "/profile/requests",
        name: "Requests",
        component: Requests,
        meta: {
          title: "requests",
        },
      },
      {
        path: "/profile/reservations",
        name: "Reservations",
        component: Reservations,
        meta: {
          title: "reservations",
        },
      },
    ],
  },
  // Cart
  {
    path: "/cart",
    name: "CartPage",
    component: CartPage,
    meta: {
      title: "cart",
    },
    children: [
      {
        path: "/",
        name: "CartList",
        component: CartList,
        meta: {
          title: "cart",
        },
      },
      {
        path: "/cart/checkout",
        name: "Checkout",
        component: Checkout,
        meta: {
          title: "checkout",
        },
      },
    ],
  },
  // Wishlist
  {
    path: "/wishlist",
    name: "WishlistPage",
    component: WishlistPage,
    meta: {
      title: "wishlist",
    },
  },

  // Products
  {
    path: "/products",
    name: "ProductsPage",
    component: ProductsPage,
    meta: {
      title: "products",
    },
  },
  {
    path: "/products/:brand_name/:product_name/:uid",
    name: "ProductsPage",
    component: ProductPage,
    meta: {
      title: "products",
    },
  },
  // Booking
  {
    path: "/booking",
    name: "BookingPage",
    component: BookingPage,
    meta: {
      title: "booking",
    },
  },

  // Blogs
  {
    path: "/blogs",
    name: "BlogsPage",
    component: BlogsPage,
    meta: {
      title: "blogs",
    },
  },
  {
    path: "/blogs/:title/:uid",
    name: "BlogPage",
    component: BlogPage,
    meta: {
      title: "blog",
    },
  },

  // Static Pages

  {
    path: "/about",
    name: "AboutUSPage",
    component: AboutUSPage,
    meta: {
      title: "about us",
    },
  },
  {
    path: "/contact",
    name: "ContactUSPage",
    component: ContactUSPage,
    meta: {
      title: "contact us",
    },
  },
  {
    path: "/delivery",
    name: "DeliveryPage",
    component: DeliveryPage,
    meta: {
      title: "delivery",
    },
  },
  {
    path: "/faq",
    name: "FAQPage",
    component: FAQPage,
    meta: {
      title: "faq",
    },
  },
  {
    path: "/gift",
    name: "GiftPage",
    component: GiftPage,
    meta: {
      title: "gift",
    },
  },
  {
    path: "/privacy",
    name: "PrivacyPage",
    component: PrivacyPage,
    meta: {
      title: "privacy policy",
    },
  },
  {
    path: "/return",
    name: "ReturnPolicyPage",
    component: ReturnPolicyPage,
    meta: {
      title: "return policy",
    },
  },
  {
    path: "/personal-privacy",
    name: "PersonalPrivacy",
    component: PersonalPrivacy,
    meta: {
      title: "personal privacy",
    },
  },
  {
    path: "/access-options",
    name: "AccessOptions",
    component: AccessOptions,
    meta: {
      title: "access, modification and options",
    },
  },
  {
    path: "/third-party-websites",
    name: "ThirdPartyWebsites",
    component: ThirdPartyWebsites,
    meta: {
      title: "third party websites",
    },
  },
  {
    path: "/information-period",
    name: "InformationPeriod",
    component: InformationPeriod,
    meta: {
      title: "information retention period",
    },
  },
  {
    path: "/sensitive-information",
    name: "SensitiveInformation",
    component: SensitiveInformation,
    meta: {
      title: "sensitive information",
    },
  },
  {
    path: "/security",
    name: "Security",
    component: Security,
    meta: {
      title: "security",
    },
  },
];
