<template>
    <v-col :cols="cols ? cols[0] : 12" :sm="cols ? cols[0] : 12" :md="cols ? cols[1] : 6" :lg="cols ? cols[2] : 4"
    :offset="offset ? offset[0] : 0"  :offset-sm="offset ? offset[0] : 0" :offset-md="offset ? offset[1] : 0" :offset-lg="offset ? offset[2] : 0"
       class="formControl d-flex" :class="paddingY + ' ' + paddingX"
      :dir="dir ? dir : $vuetify.rtl ? 'rtl' : 'ltr'">
      <!-- If Label is Inline -->
      <div style="width: 30%" v-if="labelInline == true">
        <label v-if="label && type !== 'checkbox' && type !== 'switch' && type !== 'dropzone'">
          {{ $t(label) }}
          <span class="error--text" v-if="required == true">*</span>
        </label>
      </div>
  
      <div :style="labelInline == true ? 'width: 70%' : 'width: 100%'">
  
        <!-- Text  -->
  
        <TextInput v-if="type == 'text'" :value="value" :name="name" :label="label" :labelInline="labelInline"
          :required="required" :hideDetails="hideDetails" :disabled="disabled" :isLoading="isLoading" :reverse="reverse"
          :color="color" :backgroundColor="backgroundColor" :icon="icon" :solo="solo" :dense="dense" :classes="classes" :clearable="clearable"
          :keydownEnter="(event) => keydownEnter(event)" :onChange="(event) => onChange(event)" @input="input = $event">
          <template v-slot:append-outer>
            <slot name="append-outer" />
          </template>
          <template v-slot:append>
            <slot name="append" />
          </template>
          <template v-slot:prepend-inner>
            <slot name="prepend-inner" />
          </template>
          <template v-slot:prepend>
            <slot name="prepend" />
          </template>
        </TextInput>
  
        <!-- // Text  -->
  
        <!--  Number -->
  
        <NumberInput v-if="type == 'number'" :value="value" :name="name" :label="label" :labelInline="labelInline"
          :required="required" :hideDetails="hideDetails" :disabled="disabled" :isLoading="isLoading" :reverse="reverse"
          :color="color" :backgroundColor="backgroundColor" :icon="icon" :solo="solo" :dense="dense" :classes="classes" :clearable="clearable"
          :acceptZero="acceptZero" :keydownEnter="(event) => keydownEnter(event)" :onChange="(event) => onChange(event)"
          @input="input = $event">
          <template v-slot:append-outer>
            <slot name="append-outer" />
          </template>
          <template v-slot:append>
            <slot name="append" />
          </template>
          <template v-slot:prepend-inner>
            <slot name="prepend-inner" />
          </template>
          <template v-slot:prepend>
            <slot name="prepend" />
          </template>
        </NumberInput>
  
        <!-- // Number -->
  
        <!-- Percentage -->
  
        <NumberInput v-if="type == 'percentage'" :value="value" :name="name" :label="label" :labelInline="labelInline"
          :required="required" :hideDetails="hideDetails" :disabled="disabled" :isLoading="isLoading" :reverse="reverse"
          :color="color" :backgroundColor="backgroundColor" :icon="icon" :solo="solo" :dense="dense" :classes="classes" :clearable="clearable"
          :acceptZero="acceptZero" :keydownEnter="(event) => keydownEnter(event)" :onChange="(event) => onChange(event)"
          @input="input = $event">
          <template v-slot:append-outer>
            <slot name="append-outer" />
          </template>
          <template v-slot:append>
            <slot name="append" />
          </template>
          <template v-slot:prepend-inner>
            <slot name="prepend-inner" />
          </template>
          <template v-slot:prepend>
            <slot name="prepend" />
          </template>
        </NumberInput>
  
        <!-- // Percentage -->
  
        <!-- Float Number -->
  
        <FloatInput v-if="type == 'float'" :backValueMethod="setFloatValue" :value="String(input || '')" :name="name"
          :label="label" :required="required" :labelInline="labelInline" :onChange="onChange" :isLoading="isLoading"
          :disabled="true" :color="color" :backgroundColor="backgroundColor" :icon="icon" :hideDetails="hideDetails" :keydownEnter="keydownEnter"
          :solo="solo" outlined :reverse="reverse" :dense="dense" :classes="classes" />
  
        <!-- // Float Number -->
  
        <!-- Date -->
  
        <DateInput v-if="type == 'date'" :value="value" :name="name" :label="label" :labelInline="labelInline"
          :required="required" :hideDetails="hideDetails" :disabled="disabled" :isLoading="isLoading" :reverse="reverse"
          :color="color" :backgroundColor="backgroundColor" :icon="icon" :solo="solo" :dense="dense" :classes="classes" :clearable="clearable"
          :acceptZero="acceptZero" :keydownEnter="(event) => keydownEnter(event)" :onChange="(event) => onChange(event)"
          :isPickerOpened="(event) => isPickerOpened(event)" @input="input = $event" :minDate="minDate"
          :maxDate="maxDate">
          <template v-slot:append-outer>
            <slot name="append-outer" />
          </template>
          <template v-slot:append>
            <slot name="append" />
          </template>
          <template v-slot:prepend-inner>
            <slot name="prepend-inner" />
          </template>
          <template v-slot:prepend>
            <slot name="prepend" />
          </template>
        </DateInput>
  
        <!-- // Date -->
  
        <!-- Time -->
  
        <TimeInput v-if="type == 'time'" :value="value" :name="name" :label="label" :labelInline="labelInline"
          :required="required" :hideDetails="hideDetails" :disabled="disabled" :isLoading="isLoading" :reverse="reverse"
          :color="color" :backgroundColor="backgroundColor" :icon="icon" :solo="solo" :dense="dense" :classes="classes" :clearable="clearable"
          :acceptZero="acceptZero" :keydownEnter="(event) => keydownEnter(event)" :onChange="(event) => onChange(event)"
          :isPickerOpened="(event) => isPickerOpened(event)" @input="input = $event" :minDate="minDate"
          :maxDate="maxDate">
          <template v-slot:append-outer>
            <slot name="append-outer" />
          </template>
          <template v-slot:append>
            <slot name="append" />
          </template>
          <template v-slot:prepend-inner>
            <slot name="prepend-inner" />
          </template>
          <template v-slot:prepend>
            <slot name="prepend" />
          </template>
        </TimeInput>
  
        <!-- // Time -->
  
  
        <!-- Textarea -->
  
        <TextAreaInput v-if="type == 'textarea'" :value="value" :name="name" :label="label" :labelInline="labelInline"
          :required="required" :hideDetails="hideDetails" :disabled="disabled" :isLoading="isLoading" :reverse="reverse"
          :color="color" :backgroundColor="backgroundColor" :icon="icon" :solo="solo" :dense="dense" :classes="classes" :clearable="clearable"
          :keydownEnter="(event) => keydownEnter(event)" :onChange="(event) => onChange(event)" @input="input = $event"
          :rowsNumber="rowsNumber">
          <template v-slot:append-outer>
            <slot name="append-outer" />
          </template>
          <template v-slot:append>
            <slot name="append" />
          </template>
          <template v-slot:prepend-inner>
            <slot name="prepend-inner" />
          </template>
          <template v-slot:prepend>
            <slot name="prepend" />
          </template>
        </TextAreaInput>
  
        <!-- // Textarea -->
  
        <!-- Email-->
  
        <EmailInput v-if="type == 'email'" :value="value" :name="name" :label="label" :labelInline="labelInline"
          :required="required" :hideDetails="hideDetails" :disabled="disabled" :isLoading="isLoading" :reverse="reverse"
          :color="color" :backgroundColor="backgroundColor" :icon="icon" :solo="solo" :dense="dense" :classes="classes" :clearable="clearable"
          :keydownEnter="(event) => keydownEnter(event)" :onChange="(event) => onChange(event)" @input="input = $event">
          <template v-slot:append-outer>
            <slot name="append-outer" />
          </template>
          <template v-slot:append>
            <slot name="append" />
          </template>
          <template v-slot:prepend-inner>
            <slot name="prepend-inner" />
          </template>
          <template v-slot:prepend>
            <slot name="prepend" />
          </template>
        </EmailInput>
  
        <!-- // Email-->
  
        <!-- Password-->
        <PasswordInput v-if="type == 'password'" :value="value" :name="name" :label="label" :labelInline="labelInline"
          :required="required" :hideDetails="hideDetails" :disabled="disabled" :isLoading="isLoading" :reverse="reverse"
          :color="color" :backgroundColor="backgroundColor" :icon="icon" :solo="solo" :dense="dense" :classes="classes" :clearable="clearable"
          :keydownEnter="(event) => keydownEnter(event)" :onChange="(event) => onChange(event)" @input="input = $event">
          <template v-slot:append-outer>
            <slot name="append-outer" />
          </template>
          <template v-slot:append>
            <slot name="append" />
          </template>
          <template v-slot:prepend-inner>
            <slot name="prepend-inner" />
          </template>
          <template v-slot:prepend>
            <slot name="prepend" />
          </template>
        </PasswordInput>
  
        <!-- // Password-->
  
        <!-- Mobile-->
        <MobileInput v-if="type == 'mobile' || type == 'phone'" :value="value" :name="name" :label="label"
          :labelInline="labelInline" :required="required" :hideDetails="hideDetails" :disabled="disabled"
          :isLoading="isLoading" :reverse="reverse" :color="color" :backgroundColor="backgroundColor" :icon="icon" :solo="solo" :dense="dense"
          :classes="classes" :clearable="clearable" :keydownEnter="keydownEnter"
          :onChange="(event) => onChange(event)" @input="input = $event">
          <template v-slot:append-outer>
            <slot name="append-outer" />
          </template>
          <template v-slot:append>
            <slot name="append" />
          </template>
          <template v-slot:prepend-inner>
            <slot name="prepend-inner" />
          </template>
          <template v-slot:prepend>
            <slot name="prepend" />
          </template>
        </MobileInput>
  
        <!-- // Mobile-->
  
        <!-- File -->
  
        <FileInput v-if="type == 'file'" :value="value" :name="name" :label="label" :labelInline="labelInline"
          :required="required" :hideDetails="hideDetails" :disabled="disabled" :isLoading="isLoading" :reverse="reverse"
          :color="color" :backgroundColor="backgroundColor" :icon="icon" :solo="solo" :dense="dense" :classes="classes" :multi="multi"
          :selected_prop="selected_prop" :hideImage="hideImage" :clearable="clearable"
          :keydownEnter="(event) => keydownEnter(event)" :onChange="(event) => onChange(event)" @input="input = $event">
          <template v-slot:append-outer>
            <slot name="append-outer" />
          </template>
          <template v-slot:append>
            <slot name="append" />
          </template>
          <template v-slot:prepend-inner>
            <slot name="prepend-inner" />
          </template>
          <template v-slot:prepend>
            <slot name="prepend" />
          </template>
        </FileInput>
  
        <!-- // File -->
  
        <!-- Autocomplete || Select || Multiselect -->
  
        <AutoCompleteInput v-if="type == 'autocomplete' || type == 'multiselect' || type == 'select'" :value="value"
          :name="name" :label="label" :labelInline="labelInline" :required="required" :hideDetails="hideDetails"
          :disabled="disabled" :isLoading="isLoading" :reverse="reverse" :color="color" :backgroundColor="backgroundColor" :icon="icon" :solo="solo"
          :dense="dense" :classes="classes" :multi="multi" :lookups="lookups" :selected_prop="selected_prop"
          :selected_label="selected_label" :clearable="clearable" :keydownEnter="(event) => keydownEnter(event)"
          :onChange="(event) => onChange(event)" @input="input = $event">
          <template v-slot:append-outer>
            <slot name="append-outer" />
          </template>
          <template v-slot:append>
            <slot name="append" />
          </template>
          <template v-slot:prepend-inner>
            <slot name="prepend-inner" />
          </template>
          <template v-slot:prepend>
            <slot name="prepend" />
          </template>
        </AutoCompleteInput>
  
        <!-- // Autocomplete || Select || Multiselect -->
  
        <!-- Checkbox -->
  
        <CheckBoxInput v-if="type == 'checkbox'" :value="value" :name="name" :label="label" :labelInline="labelInline"
          :required="required" :hideDetails="hideDetails" :disabled="disabled" :isLoading="isLoading" :reverse="reverse"
          :color="color" :backgroundColor="backgroundColor" :icon="icon" :solo="solo" :dense="dense" :classes="classes" :clearable="clearable"
          :keydownEnter="(event) => keydownEnter(event)" :onChange="(event) => onChange(event)" @input="input = $event">
          <template v-slot:append-outer>
            <slot name="append-outer" />
          </template>
          <template v-slot:append>
            <slot name="append" />
          </template>
          <template v-slot:prepend-inner>
            <slot name="prepend-inner" />
          </template>
          <template v-slot:prepend>
            <slot name="prepend" />
          </template>
        </CheckBoxInput>
  
  
        <!-- // Checkbox -->
  
        <!-- Radio Group -->
  
        <RadioInput v-if="type == 'radiogroup'" :value="value" :name="name" :label="label" :labelInline="labelInline"
          :required="required" :hideDetails="hideDetails" :disabled="disabled" :isLoading="isLoading" :reverse="reverse"
          :color="color" :backgroundColor="backgroundColor" :icon="icon" :solo="solo" :dense="dense" :classes="classes" :multi="multi" :lookups="lookups"
          :selected_prop="selected_prop" :selected_label="selected_label" :clearable="clearable"
          :keydownEnter="(event) => keydownEnter(event)" :onChange="(event) => onChange(event)" @input="input = $event">
          <template v-slot:append-outer>
            <slot name="append-outer" />
          </template>
          <template v-slot:append>
            <slot name="append" />
          </template>
          <template v-slot:prepend-inner>
            <slot name="prepend-inner" />
          </template>
          <template v-slot:prepend>
            <slot name="prepend" />
          </template>
        </RadioInput>
  
        <!-- // Radio Group -->
  
        <!-- Switch -->
  
        <SwitchInput v-if="type == 'switch'" :value="value" :name="name" :label="label" :labelInline="labelInline"
          :required="required" :hideDetails="hideDetails" :disabled="disabled" :isLoading="isLoading" :reverse="reverse"
          :color="color" :backgroundColor="backgroundColor" :icon="icon" :solo="solo" :dense="dense" :classes="classes" :clearable="clearable"
          :keydownEnter="(event) => keydownEnter(event)" :onChange="(event) => onChange(event)" @input="input = $event">
          <template v-slot:append-outer>
            <slot name="append-outer" />
          </template>
          <template v-slot:append>
            <slot name="append" />
          </template>
          <template v-slot:prepend-inner>
            <slot name="prepend-inner" />
          </template>
          <template v-slot:prepend>
            <slot name="prepend" />
          </template>
        </SwitchInput>
  
        <!-- // Switch -->
  
  
        <!-- TextEditor -->
  
        <TextEditor v-if="type == 'texteditor'" :value="value" :name="name" :label="label" :labelInline="labelInline"
          :required="required" :hideDetails="hideDetails" :disabled="disabled" :isLoading="isLoading" :reverse="reverse"
          :color="color" :backgroundColor="backgroundColor" :icon="icon" :solo="solo" :dense="dense" :classes="classes" :clearable="clearable"
          :keydownEnter="(event) => keydownEnter(event)" :onChange="(event) => onChange(event)" @input="input = $event">
        </TextEditor>
  
        <!-- TextEditor -->
  
  
        <!-- dropzone -->
        <DropZone v-if="type == 'dropzone'" :value="input" :name="name" :multi="multi"
          :backValueMethod="(images) => { input = images }" :label="label" :required="required" :isLoading="isLoading"
          :color="color" :backgroundColor="backgroundColor" :icon="icon" :hideDetails="hideDetails" />
  
        <!-- // dropzone -->
      </div>
    </v-col>
</template>

<script>
import FloatInput from "../inputs/FloatInput.vue";
import DropZone from "../inputs/DropZone.vue";
import AutoCompleteInput from "../inputs/AutoCompleteInput.vue";
import TextInput from "../inputs/TextInput.vue";
import NumberInput from "../inputs/NumberInput.vue";
import DateInput from "../inputs/DateInput.vue";
import TimeInput from "../inputs/TimeInput.vue";
import EmailInput from "../inputs/EmailInput.vue";
import TextAreaInput from "../inputs/TextAreaInput.vue";
import PasswordInput from "../inputs/PasswordInput.vue";
import MobileInput from "../inputs/MobileInput.vue";
import FileInput from "../inputs/FileInput.vue";
import CheckBoxInput from "../inputs/CheckBoxInput.vue";
import RadioInput from "../inputs/RadioInput.vue";
import SwitchInput from "../inputs/SwitchInput.vue";
import TextEditor from "../inputs/TextEditor.vue";
export default {
  name: "GenericInput",
  computed: {
    input: {
      get() {
        return this.value;
      },
      set(val) {
        if (this.type == "float") {
          this.$emit("input", Number(val));
        }
        else {
          this.$emit("input", val);
        }
      },
    },

  },
  mounted() {

  },
  watch: {

  },
  methods: {
    setFloatValue(value) {
      this.input = Number(value);
    }
  },
  components: {
    TextInput,
    NumberInput,
    FloatInput,
    DateInput,
    TimeInput,
    TextAreaInput,
    EmailInput,
    AutoCompleteInput,
    MobileInput,
    PasswordInput,
    FileInput,
    DropZone,
    CheckBoxInput,
    RadioInput,
    SwitchInput,
    TextEditor,
  },
  props: {
    name: { default: null },
    label: { default: null },
    type: { default: null },
    value: { default: null },
    lookups: { default: null },
    cols: { default: null },
    offset: { default: null },
    spacer: { default: null },
    required: { default: false },
    dense: { default: true },
    lookups_value: { default: null },
    multi: { default: null },
    selected_label: { default: null },
    selected_prop: { default: null },
    onChange: { type: Function },
    isLoading: { default: null },
    disabled: { default: null },
    color: { default: "secondary" },
    icon: { default: "" },
    hideDetails: { default: false},
    labelInline: { default: false },
    clearable: { default: false },
    hideImage: { default: false },
    onClickOuter: { type: Function },
    keydownEnter: { type: Function },
    disableItem: { default: null },
    minDate: { default: null },
    maxDate: { default: null },
    isPickerOpened: { default: Function },
    placeholder: { default: null },
    paddingY: { default: "py-0" },
    paddingX: { default: null },
    solo: { default: true },
    acceptZero: { default: false },
    classes: { default: null },
    rowsNumber: { default: 3 },
    dir: { default: null },
    reverse: { default: false },
    backgroundColor:  { default: 'light' },
  },
  data: () => ({

  }),
};
</script>

<style lang="scss">

</style>